
#feedback-edit {
    padding-bottom: 50px;

    .panel {
        position: relative;
    }
    
    .book-copyform-loading-text {
        margin-bottom: 10px;
        text-align: center;
    }

    .book-box-audio-layout {
        .control-label-100 {
            width: 60px;
        }
        .form-control-100 {
            width: calc(100% - 60px)
        }
    }
    .book-box-schedule {
        .control-label-100 {
            width: 75px;
        }
        .form-control-100 {
            width: calc(100% - 75px);
        }
    }
    .book-box-specifications {
        .control-label-100 {
            width: 90px;
        }
        .form-control-100 {
            width: calc(100% - 90px);
        }
    }

    .book-box-delivery-type {
        .book-delivery-type-sat {
            .control-label-100 {
                width: 70px;
            }
            .form-control-100 {
                width: calc(100% - 70px);
            }
            .row {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .book-panel-localnotes {
        .checkbox {
            margin-bottom: 10px;
        }
    }
    .book-ingestion {
        position: absolute;
        right: 86px;
        top: -24px;
        z-index: 5;
    }
    .book-action-buttons {
        position: absolute;
        right: 0;
        top: -25px;
        z-index: 5;
        .btn-danger, .btn-warning {
            margin-left: 5px;
        }
    }

    .panel.panel-info.panel-info-wrapper,
    .panel.panel-info.panel-info-wrapper-width {
         margin-right: 5px;
    }

    .panel.panel-info.panel-info-wrapper {
        display: inline-block;
    }

    .panel.panel-info.panel-info-wrapper-width {

    }

    .control-label.control-label-100.duration {
        display: inline;
    }

    .book-input-date-only{
        padding-top: 5px;
        height: 30.4px;
        input {
            border-radius: 3px;
            float: right;
            width: 72px !important;
            margin-left: 8px;
            margin-top: -5px;
        }

        span {
            font-size: 12px;
            //font-weight: 700;
            margin-top: 8px;
        }
    }

    .radio-inline-area,
    .checkbox-inline-issue {
        span {
            font-size: 12px;
            font-weight: 0 !important;
        }

        
    }

    .additional-info {
        margin-top: 8px;
        .control-label-100 {
            width: 70px;
            
        }

        .control-label.control-label-100.control-label-no-bold {
            display: inline-block;
            span {
                font-size: 12px;
                font-weight: 0 !important;
            }
        }

        .form-control-100 {
            width: calc(100% - 70px);
        }
    }

    // span.checkbox-inline-issue {
    //     font-size: 12px;
    // }

    // .book-datetime {
    //     .book-datetime-datepicker-hidden {
    //         display: block !important;
    //         height: 0 !important;
    //         overflow: hidden !important;
    //         width: 0 !important;
    //     }
    //     .book-input-date-only,
    //     .book-input-time-only {
    //         //float: left;
    //         display: block;
    //         input {
    //             display: block;
    //         }
    //     }
    //     .book-input-date {
    //         width: 140px;
    //     }
    //     .book-input-time {
    //         margin-left: 5px;
    //         width: 62px;
    //     }
    // }

    .row.error-report {
        margin-left: 0px;
        margin-right: -5px;
        padding-left: 0px;

        .col-no-padding {
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 5px;
            select {
                font-size: 12px !important;
            }

            .input-other-equipment {
                border-radius: 4px;
                float: right;
                width: 100% !important;
                font-size: 12px !important;
                border: 1px solid #ccc;
                height: 33px;
                //margin-left: 8px;
                //margin-top: -5px;
            }
            
        }
        
        button {
            //margin-top: 5px;
        }
    
    }

    .checkbox-in-header {
        display: inline-block;
        text-align: center;
        position: absolute;
        right: 3px;
    }
    .table,
    .book-table-no-results {
        font-size: 12px;
    }

    
}
