
#main-content:not(.book-print) {
    min-height: 100%;
    &.book-authenticated {
        padding-top: 50px;
    }
}

.book-width-50 {
  width: 50px !important;
}
.book-width-80 {
  width: 80px !important;
}
.book-width-100 {
  width: 100px !important;
}
.book-width-120 {
  width: 120px !important;
}
.book-width-200 {
    width: 200px !important;
  }
// .book-context-summary {
//   margin-bottom: 10px;
//   margin-top: -20px;
// }


.book-compact-panels {
    .panel {
        margin-bottom: 10px;
        .book-upload {
            .dropzone {
                border: none;
                margin: 0;
                padding: 0;
            }
            .book-upload-actions {
                padding-top: 2px;
            }
        }
        .dropzone {
            .dz-preview {
                padding: 2px 6px;
            }
            .dz-preview .dz-image .dz-file {
                font-size: 30px;
            }
            .dz-preview .dz-image {
                margin-right: 2px;
            }
        }

    }
    .panel-heading {
        padding: 4px 8px;
    }
    .panel-body {
        padding: 8px;
    }
    .nav {
        > li {
            > a {
                margin-right: 0;
                padding: 4px 5px;
            }
        }
    }
}
