
@import "../../style/_variables.scss";

.col-sm-6.channel-left {
  padding-right: 4px;
}
.col-sm-6.channel-right {
  padding-left: 4px;
}

.col-sm-6.channel-left,
.col-sm-6.channel-right {
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.book-sidebar-table-row.border-channel {
  border-top: 1px solid $color-grey-border;
}
.col-sm-12.audio-label,
.col-sm-6.audio-label {
  padding-left: 0px;
  padding-right: 2px;
  font-size: 10px;
  font-weight: normal;
  .col-sm-12 {
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
  }

  .control-label {
    text-align: left;
    font-size: 10px;
    padding-top: 0px;
  }

  .control-label.pair-label {
    font-size: 10px;
    padding-top: 0px;
  }

  overflow: hidden;

  .radio-inline {
    padding-left: 0px;
    padding-top: 0px;


    input[type="radio"] {
      margin-left: 0px;
      margin-top: 0px;
    }

    span {
      margin-left: 15px;
      font-weight: normal;
      font-size: 8px;
    }
  }

  .radio-inline + .radio-inline {
    margin-left: 2px;
  }

}

.col-sm-12.audio-pair,
.col-sm-6.audio-pair {
  padding-left: 0px;
  padding-right: 0px;

  .col-sm-12 {
    padding-left: 0px;
    padding-right: 0px;
  }

  select {
    font-size: 10px;
    padding: 0px;
  }

  .col-sm-6.mono-left {
    padding-left: 0px;
    padding-right: 1px;
  }
  .col-sm-6.mono-right {
    padding-left: 1px;
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  .col-sm-6.channel-left {
    padding-right:15px;

  }
  .col-sm-6.channel-right {
    padding-top: 8px;
    padding-left:15px;


  }

  .col-sm-6.audio-label {
    .radio-inline {
      padding-top: 3px;
      span {
        font-size: 10px;
      }
    }

    .control-label.pair-label {
      padding-top: 8px;
    }

    .control-label {
      font-size: 12px;
    }

    .control-label.pair-label {
      font-size: 12px;
    }
  }

  .col-sm-6.audio-pair {
    select {
      font-size: 12px;
      padding: 0px;
    }
  }
}

@media (min-width: 1300px) {
  .col-sm-6.channel-left,
  .col-sm-6.channel-right {
    .col-sm-6.audio-label {
      width: 105px;
    }

    .col-sm-6.audio-pair {
      width: calc(100% - 105px);
    }

  }

  .col-lg-6.audio-label {
    width: 105px;
  }

  .col-lg-6.audio-pair {
    width: calc(100% - 105px);
  }

}

.book-sidebar-table-row-nowrap.book-width-40-percents {
  width: 40%;
}
.book-width-60-percents {
  width: 60%;
}
