

#production-template-edit {

    padding-bottom: 50px;

    .panel {
        position: relative;
    }

    .book-box-housenumbers {
      display: inline-block;
      .form-group-sm {
        width: 210px;
        float: left;
      }
      .control-label-100 {
        text-align: left;
        padding-top: 15px;
        width: 50px;
      }
      .form-control-100 {
        padding-top: 10px;
        padding-left: 0px;
        margin-left: 0px;
        margin-right: 41px;
      }
    }

    .book-action-buttons {
        position: absolute;
        right: 0;
        top: -25px;
        z-index: 5;
        .btn-danger {
            margin-left: 5px;
        }
        .btn-info {
            margin-left: 5px;
        }
    }
}
