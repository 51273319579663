
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/_variables.scss";

.book-mcr-contribution {
  .book-mcr-status .book-mcr-status-selection {
      width: 84px !important; // Required by IE.
  }
}
.book-mcr-status {
    align-items: center;
    display: flex;
    position: relative;
    text-align: center;
    //width: 30px;
    &.book-mcr-status-locked {
        .book-mcr-status-icon {
            cursor: default;
        }
    }
    &.book-mcr-status-disabled {
        cursor: not-allowed;
        position: relative;
        opacity: 0.3;
        &:after {
            bottom: 0;
            content: " ";
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
        > .book-mcr-status-icon {
            opacity: .4;
        }
    }
    &.book-mcr-status-expanded:not(.book-mcr-status-processing) {
        z-index: 900;
        > .book-mcr-status-icon {
            background: $color-white;
            border-color: $color-grey-border;
            border-radius: 4px 4px 0 0;
            z-index: 901;
        }
        .book-mcr-status-selection {
            display: flex;
            z-index: 900;
        }
    }
    > .book-mcr-status-icon {
        border: 1px solid transparent;
        border-bottom: none;
        padding: 3px 6px;

        &.book-mcr-status-1 {
            color: $color-red;
        }
        &.book-mcr-status-2 {
            color: $color-red;
        }
        &.book-mcr-status-3 {
            color: $color-yellow;
        }
        &.book-mcr-status-3b {
            color: $color-red;
        }
        &.book-mcr-status-3c {
            color: $color-green;
        }
        &.book-mcr-status-4 {
            color: $color-green;
        }
    }
    .book-mcr-status-icon {
        cursor: pointer;
        display: inline-block;
        position: relative;
        img {
            max-height: 20px;
            max-width: 20px;
            width: auto;
        }
    }
    .book-mcr-status-icon-planning,
    .book-mcr-status-icon-lineup {
        color: $color-red;
    }
    .book-mcr-status-icon-standby {
        color: $color-yellow;
    }
    .book-mcr-status-icon-finished {
        color: $color-green;
    }
    .book-mcr-status-selection {
        background: $color-white;
        border: 1px solid $color-grey-border;
        border-radius: 0 4px 4px 4px;
        box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.4);
        cursor: pointer;
        display: none;
        left: 0;
        padding: 0 5px;
        position: absolute;
        top: 26px;
        width: 110px; // Required by IE.
        .book-mcr-status-icon {
            line-height: 30px;
            padding: 4px;
            vertical-align: middle;
            &:hover {
                img {
                    max-height: 24px;
                }
            }
        }
    }
    

    .book-mcr-status-item {
        background: none;
        border: 2px solid rgba($color-text, .5);
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        height: 20px;
        padding: 0;
        position: relative;
        user-select: none;
        vertical-align: middle;
        width: 20px;
        // z-index: 1;
        &:focus {
            outline: none;
        }
        &:before {
            display: block;
            content: " ";
            left: 50%;
            //opacity: .3;
            top: 50%;
        }
        
        // &:after {
        //     border-top: 2px solid $color-red;
        //     display: block;
        //     content: " ";
        //     left: -8px;
        //     position: absolute;
        //     top: 7px;
        //     transform: rotate(45deg);
        //     width: 32px;
        // }
        //&:hover:not(.book-mcr-status-item-disabled):not(.book-mcr-status-item-readonly),
        &.book-mcr-status-item-active-1 {
            border-color: rgba($color-text, 1);
            color: $color-green;
            &:before {
                background-color: $color-blue-info;
                width: 5px;
                height: 12px;
                border-radius: 100px;
                margin-left: 2px;
                padding-left: 12px;
            }
            // &:after {
            //     display: none;
            // }
            svg {
                opacity: 1;
            }
        }
        &.book-mcr-status-item-active-2 {
            border-color: rgba($color-text, 1);
            color: $color-green;
            &:before {
                background-color: #22CC22;
                width: 5px;
                height: 12px;
                border-radius: 100px;
                margin-left: 2px;
                padding-left: 12px;
            }
            // &:after {
            //     display: none;
            // }
            svg {
                opacity: 1;
            }
        }
        &.book-mcr-status-item-active-3 {
            border-color: rgba($color-text, 1);
            color: $color-red;
            &:before {
                background-color: $color-yellow;
                width: 5px;
                height: 12px;
                border-radius: 100px;
                margin-left: 2px;
                padding-left: 12px;
            }
            // &:after {
            //     display: none;
            // }
            svg {
                opacity: 1;
            }
        }
        &.book-mcr-status-item-active-4 {
            border-color: rgba($color-text, 1);
            color: $color-green;
            &:before {
                background-color: #bfbebe;
                width: 5px;
                height: 12px;
                border-radius: 100px;
                margin-left: 2px;
                padding-left: 12px;
            }
            // &:after {
            //     display: none;
            // }
            svg {
                opacity: 1;
            }
        }
        //&:hover:not(.book-mcr-status-item-disabled):not(.book-mcr-status-item-readonly) {
          //box-shadow: 0px 1px 10px -1px $color-shadow;
        //}
        &.book-mcr-status-item-disabled {
            border: 2px solid #8b8c8b;
            opacity: 1 !important;
            cursor: not-allowed;
            &:after {
                opacity: .5;
            }
            &:after {
                display: none;
            }
            svg {
                //opacity: 1 !important;
            }
        }
        &.book-mcr-status-item-readonly {
            cursor: default !important;
        }
        &.book-mcr-status-item-1{
            &:hover{
                opacity: 1;
                border-color: rgba($color-text, 1);
                box-shadow: 0px 1px 10px -1px $color-shadow;
                &:before {
                    background-color: $color-blue-info;
                    width: 5px;
                    height: 12px;
                    border-radius: 100px;
                    margin-left: 2px;
                    padding-left: 12px;
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                }
            }
        }
        &.book-mcr-status-item-2{
            &:hover{
                opacity: 1;
                border-color: rgba($color-text, 1);
                box-shadow: 0px 1px 10px -1px $color-shadow;
                &:before {
                    background-color: #22CC22;
                    width: 5px;
                    height: 12px;
                    border-radius: 100px;
                    margin-left: 2px;
                    padding-left: 12px;
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                }
            }
        }
        &.book-mcr-status-item-3{
            &:hover{
                opacity: 1;
                border-color: rgba($color-text, 1);
                box-shadow: 0px 1px 10px -1px $color-shadow;
                &:before {
                    background-color: $color-yellow;
                    width: 5px;
                    height: 12px;
                    border-radius: 100px;
                    margin-left: 2px;
                    padding-left: 12px;
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                }
            }
        }
        &.book-mcr-status-item-4{
            &:hover{
                opacity: 1;
                border-color: rgba($color-text, 1);
                box-shadow: 0px 1px 10px -1px $color-shadow;
                &:before {
                    background-color: #bfbebe;
                    width: 5px;
                    height: 12px;
                    border-radius: 100px;
                    margin-left: 2px;
                    padding-left: 12px;
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                }
            }
        }
        + .book-mcr-status-item {
            margin-left: 4px;
        }
        
        svg {
            opacity: .0;
        }
    }
    
    .book-mcr-status-overlay {
        bottom: 0;
        cursor: not-allowed;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        .book-loader {
            position: relative;
            z-index: 3;
        }
    }
}
