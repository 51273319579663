
@page {
    size: A4;
    margin: .5cm;

}

@media print {
    .book-no-print,
    .book-no-print * {
        display: none !important;
    }
    html,
    body {
        height: 297mm;
        width: 210mm;
    }
    div.print-page :last-child{
        page-break-after: avoid !important;
    }
    div.print-page {
        page-break-after: always;
        height:auto !important;
    }

    .pageBreak{
        page-break-after: always;
    }

    div.book-print-page {
        border: none !important;
        margin-bottom: 30px;
        padding:20px;
    }
    h3 {
        font-size: 15pt;
        margin-top: 25px;
        padding-top:25px;
    }

}

#main-content.book-print {
    height: 297mm;
    margin: 0 auto;
    padding: 20px;
    width: 190mm;
}

.book-print-content {

}

.book-print-page {
    border: 1px solid $color-grey-border;
    padding: 20px;
    > * {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
        &:first-child {
            margin-top: 0;
        }
    }
    h3 {
        font-size: 15pt;
        margin-top: 25px;
        padding-top:25px;
    }
    .book-print-title {
        align-items: flex-end;
        display: flex;
        margin-top: 50px;
        > *:first-child {
            flex: 1;
        }
    }
    .panel-heading {
        background-color: transparent !important;
        font-weight: bold;
        padding: 6px 8px;
    }
    .table {
        &:last-child {
            margin-bottom: 0;
        }
        th,
        td {
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
}
