
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
.book-exit-print {
    // background: rgba(#FFFFFF, .95);
    // border: 1px solid $color-grey-border !important;
    border-radius: 0 0 0 4px;
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.4);
    position: fixed !important;
    right: 10px;
    text-align: center;
    top: 10px;
    z-index: 1000;
    button {
        font-size: 1.3em;
    }
    .book-print-close {
    }
}
