@import "../../style/_variables.scss";

$task: $color-list-item-unsigned;
$placeholder: $color-list-item-conflict-warning;
$production: $color-blue-info;
$declined: $color-red;

:export {
    task: $task;
    placeholder: $placeholder;
    production: $production;
    declined: $declined;
}