
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/_variables.scss";

.book-header-loader {
    @keyframes ellipsis {
        0% {
            content: " ";
        }
        25% {
            content: ".";
        }
        50% {
            content: "..";
        }
        75% {
            content: "..."
        }
    }
    align-items: center;
    background: $color-white;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 20px -5px $color-shadow;
    display: flex;
    height: 34px;
    left: 50%;
    margin-left: -65px;
    opacity: 0;
    padding: 2px 10px 3px;
    position: fixed;
    top: 0;
    transform: translateY(-100%);
    transition: all .2s ease;
    width: 130px;
    z-index: 100002;
    &.visible {
        opacity: 1;
        transform: translateY(0);
    }
    .book-header-loader-text {
        margin-left: 12px;
        &:after {
            content: "...";
            animation: ellipsis 1s ease 0s infinite;
        }
    }
}
