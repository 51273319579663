
// *****************************************************************************
// Breakpoints.

$min-mobile: 0;
$max-mobile: 400px;
$mobile: $min-mobile $max-mobile;

$min-phablet: 401px;
$max-phablet: 550px;
$phablet: $min-phablet $max-phablet;

$min-tablet: 551px;
$max-tablet: 855px;
$tablet: $min-tablet $max-tablet;

$min-netbook: 856px;
$max-netbook: 960px;
$netbook: $min-netbook $max-netbook;

$min-desktop: 961px;
$desktop: min-width $min-desktop;


// *****************************************************************************
// Colors.

$color-white: #FFF;
$color-black: #000;
$color-text: #333333;
$color-menu-text-hover: #262626;

$color-grey-light: #EEEEEE;
$color-grey-light-transparent: rgba(lighten($color-black, 96%), .7);

$color-grey: lighten($color-black, 46.7%);
$color-grey-transparent: rgba(lighten($color-black, 46.7%), .7);

$color-grey-border: #DDDDDD;
$color-grey-default: #777777;

$color-blue: #286090;
$color-blue-info: #5bc0de;
$color-green: #5CB85C;

$color-blue-primary: #337ab7;

$color-red: #d9534f;
$color-yellow: #f0ad4e;

$color-row-odd: rgba(lighten($color-black, 46.7%), .05);

$color-form-control: #CCC;

$color-shadow: rgba($color-black, .7);

$color-background-warning: #fcf8e3;

$color-bg-success: #dff0d8;
$color-bg-info: #d9edf7;


// MCR items.

$color-item-odd: lighten(#f5f5f5, 2%);
$color-item-hover: #f5f5f5;
$color-item-highlighted: darken($color-item-hover, 3%);


// List/timeline items.

$color-list-item-passed: darken($color-grey-light, 10%);
$color-list-item-text-passed: #333333;
$color-list-item-selected-passed: $color-grey;
$color-list-item-selected-text-passed: #fff;

$color-list-item-signed: $color-green;
$color-list-item-text-signed: #fff;

// $color-list-item-unsigned-priority: #f2dede;
// $color-list-item-text-unsigned-priority: #333;
$color-list-item-unsigned-priority: $color-red;
$color-list-item-text-unsigned-priority: #fff;
$color-list-item-selected-unsigned-priority: #d9534f;
$color-list-item-selected-text-unsigned-priority: #fff;
$color-list-item-conflict-warning: #942522;

// $color-list-item-unsigned: #fcf8e3;
// $color-list-item-text-unsigned: #333;
$color-list-item-unsigned: #f0ad4e;
$color-list-item-text-unsigned: $color-text;
$color-list-item-selected-unsigned: #f0ad4e;
$color-list-item-selected-text-unsigned: #fff;
$color-list-item-special: #ec5f27;

$color-list-item-option: $color-blue;
$color-list-item-text-option: #fff;
$color-list-item-selected-option: $color-blue;
$color-list-item-selected-text-option: #fff;

$color-feed: $color-blue;
$color-feed-text: #fff;
$color-production: $color-blue-info;
$color-production-text: #fff;
