
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/_variables.scss";
@import "../../../style/darkmode.scss";

.book-mcr-comment {
  min-height: 40px;
  position: relative;
  .modal-footer {
    .book-loader {
      float: left;
      margin-left: 5px;
      margin-top: 5px;
    }
  }
  .bolder-italic {
    padding-top: 8px;
    font-style: italic;
    font-weight: 700;
  }
  .book-table {
    border: none;
    margin-bottom: 0;
  }
  .table-modal {
    margin-top: 10px;
  }
  .table {
    margin-bottom: 0;
  }
  > .book-loader {
    padding: 9px 0;
  }
  .book-help-message-conflict {
    line-height: 1.4em;
    text-align: left;
  }

  .book-overview-item-title > span  {
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.panel-body > .book-mcr-comment {
  margin: -15px;
}
.book-compact-panels .panel-body > .book-mcr-comment {
  margin: -8px;
}
