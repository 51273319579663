
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
.book-table {
    margin-bottom: 0 !important;
    position: relative;
    width: 100%;
    .table {
        margin-bottom: 0 !important;
    }
    th {
        white-space: nowrap;
        &.book-sortable {
            cursor: pointer;
            user-select: none;
        }
    }
    tr.book-item-fadeout {
        background-color: #2991b1 !important;
        color: white;
        opacity: 1;
    }
    tr {
    }
    td {
        vertical-align: middle !important;
        padding: 4px 8px;
        line-height: 1.42857;
        vertical-align: top;
        border-top: 1px solid #ddd;
        > span {
            vertical-align: middle;
        }
        .label {
            vertical-align: calc(1px);
        }
    }
    .label {
        user-select: none;
    }
    .book-marker {
        margin-right: 8px;
        position: relative;
    }
    .book-table-actions {
        text-align: right;
        white-space: nowrap;
        width: 150px;
        button {
            margin-right: 2px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .housenumber-form {
        display: block;
        font-size: 14px;
        color: #555555;
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-left: 5px;
        padding-left: 5px;
        width: 90%;
    }
    .book-table-search {
        @include breakpoint(min-width $min-tablet) {
            float: left;
        }
    }
    .book-table-button-filter {
        float: right;
        margin: 10px 10px 0 0;
        @include breakpoint(min-width $min-tablet) {
            display: none;
        }
    }
    .book-table-filters {
        border-bottom: 1px solid #DDDDDD;
        @include breakpoint(min-width $min-tablet) {
            text-align: right;
        }
        > * {
            margin: 10px 10px 10px 8px !important;
            vertical-align: middle;
            @include breakpoint(min-width $min-tablet) {
                display: inline-block;
            }
        }
    }
    .book-table-filter-label,
    .book-table-filter-field {
        display: inline-block;
    }
    .book-table-filter-label {
        margin-right: 5px;
    }
    .book-table-filter-field {
        width: auto !important;
    }
    .book-table-button-clear-wrapper {
        margin-left: 0 !important;
        text-align: right;
    }
    .book-table-no-results {
        align-self: center;
        align-items: center;
        display: flex;
        flex: 1;
        justify-content: center;
        padding: 10px;
        text-align: center;
    }
    .book-tr {
        display: table-row !important;
        vertical-align: inherit;
        border-color: inherit;
        border-collapse: separate;
        border-spacing: 2px;
    }
    .label-exceeded {
        background-color: $color-list-item-conflict-warning;
    }
    tr.strikeout td{
        position: relative;
    }
    tr.strikeout td:before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 0;
        border-bottom: 1px solid #111;
        width: 100%;
    }
}
.book-table {
  > .dark-layout:hover {
    background: #0D2936 !important;
  }
}
