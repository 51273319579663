

.test-background{
    background: #3c0000;
    .navbar-nav > li > a {
        color: #fff;
    }
    .navbar-nav > li > a:hover{
      color: #bcbcbc;
    }
}

.stage-background{
  background: #17212d;
  .navbar-nav > li > a{
    color: #fff;
  }
  .navbar-nav > li > a:hover{
    color: #bcbcbc;
  }
}

#notifications {
    .dropdown-toggle {
        padding-top: 16px;
    }
    .notification-message {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 11px;
        font-style: italic;
    }
    .notification-count {
        position: absolute; 
        top: 9px; 
        font-size: 11px;
    }
    .notification-no-new {
        padding-left: 20px;
        padding-right: 20px;
    }
    .notification-li {
        cursor: pointer;
    }
}
