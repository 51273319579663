@import "../../../style/_variables.scss";

#feed-edit-print {
  .book-print-feed-audio-layout {
    .book-print-feed-audio-layout-isdn {
      width: 75px;
    }
  }
  .book-print-feed-encryption {
    .book-print-feed-encryption-country,
    .book-print-feed-encryption-value {
      display: block;
    }
    .book-print-feed-encryption-country {
      float: left;
    }
    .book-print-feed-encryption-value {
      overflow: hidden;
      padding: 1px 0 0 10px;
    }
    .book-print-feed-encryption-local {
      + .book-print-feed-encryption-local {
        margin-top: 5px;
      }
    }
  }
  .book-print-feed-local-notes {
    .book-print-feed-local-note-country,
    .book-print-feed-local-note-value {
      display: block;
    }
    .book-print-feed-local-note-country {
      float: left;
    }
    .book-print-feed-local-note-value {
      overflow: hidden;
      padding: 1px 0 0 10px;
    }
    .book-print-feed-local-note {
      + .book-print-feed-local-note {
        margin-top: 5px;
      }
    }
  }
}


#feed-edit {
  padding-bottom: 50px;

  .panel {
    position: relative;
  }

  .book-copyform-loading-text {
    margin-bottom: 10px;
    text-align: center;
  }
  .book-box-housenumbers {
    display: inline-block;
    .form-group-sm {
      width: 295px;
      float: left;
    }
    .control-label-100 {
      text-align: left;
      padding-top: 15px;
      padding-left: 10px;
      width: 50px;
    }
    .form-control-100 {
      padding-top: 10px;
      padding-left: 0px;
      margin-left: 0px;
      float: right;
      margin-right: 33px;
    }
  }
  .book-box-audio-layout {
    .control-label-100 {
      width: 60px;
    }
    .form-control-100 {
      width: calc(100% - 60px)
    }
  }
  .book-box-schedule {
    .control-label-100 {
      width: 75px;
    }
    .form-control-100 {
      width: calc(100% - 75px);
    }
  }
  .book-box-specifications {
    .control-label-100 {
      width: 90px;
    }
    .form-control-100 {
      width: calc(100% - 90px);
    }
  }

  .book-box-delivery-type {
    .book-delivery-type-sat {
      .control-label-100 {
        width: 70px;
      }
      .form-control-100 {
        width: calc(100% - 70px);
      }
      .row {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .book-panel-localnotes {
    .checkbox {
      margin-bottom: 10px;
    }
  }
  .book-ingestion {
    position: absolute;
    right: 86px;
    top: -24px;
    z-index: 5;
  }
  .book-action-buttons {
    position: absolute;
    right: 0;
    top: -25px;
    z-index: 5;
    .btn-danger, .btn-info, .btn-warning, .btn-special {
      margin-left: 5px;
    }
  }

  .book-fork-placeholder-missed-mandatory {
    border: 2px solid $color-red;
  }

  // .btn-warning-template {
  //     margin-right: 5px;
  // }

}
