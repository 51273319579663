
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.book-association-wrapper {
    .panel-body {
        padding: 0;
        position: relative;
    }
    .book-button-add-association {
        position: absolute;
        right: 0;
        top: -27px;
    }
    .panel-heading ~ .book-association {
        .book-button-add-association {
            right: 3px;
            top: -25px;
        }
    }
}
.book-association {
    min-height: 40px;
    position: relative;
    > .book-loader {
        padding: 9px 0;
    }
    .modal-footer {
        .book-loader {
          float: left;
          margin-left: 5px;
          margin-top: 5px;
        }
    }
}
.panel-body > .book-association {
    margin: -8px;
}
