
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
@import "../../style/darkmode.scss";

.book-mcr-items-wrapper {
  margin-bottom: 20px;
  &.book-sidebar-opened {
    .book-mcr-view {
      .book-mcr-labels {
        &.affix {
          min-width: 1000px;
          right: 315px;
        }
      }
    }
    .book-mcr-labels {
      margin-right: 5px;
    }
    .book-mcr-rows {
      padding-right: 5px;
    }
  }
  .book-sidebar {
    border: 1px solid $color-grey-border;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba($color-black, 0.05);
    margin-bottom: 1px;
    .book-equipment-selection {
      margin: 0 -10px;
      .book-button-add-equipment {
        position: absolute;
        right: 8px;
        top: -25px;
      }
    }
  }
}
.book-mcr-view {
  flex: 1;
  flex-flow: row wrap;
  overflow-x: auto;
  padding-bottom: 44px;
  position: relative;
  width: 100%;
  > .book-loader {
    margin: 0 auto;
  }
  .book-mcr-no-results {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    min-height: 70px;
    padding: 10px;
    text-align: center;
    width: 100%;
  }
  .light-border {
    border-right: 1px solid #e2e2e2;
  }
  .panel:not(.book-mcr-no-results) {
    padding-left: 2px;
    padding-right: 2px;
  }
  .panel-body {
    min-height: 30px;
    padding: 0;
  }
  .book-mcr-transition-group {
    display: block;
    position: relative;
    width: 100%;
    z-index: 0;
  }
  .housenumber-form {
    display: block;
    font-size: 14px;
    color: #555555;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 5px;
    padding-left: 5px;
    width: 90%;
  }
  .book-mcr-row,
  .book-mcr-row-child {
    transition: all .3s ease;
  }
  .book-mcr-row {
    margin-top: 5px;
    position: relative;
    &.book-mcr-selected {
      > .dark-layout {
        background: #0D2936 !important;
      }
      > .panel {
        background: #5093ad;
        color: white;
        .book-mcr-column {
          &.active {
            background: $color-blue;
          }
        }
      }
      .btn,
      .label,
      .book-square {
        //border: 1px solid rgba($color-white, .4);
      }
      .book-ingestion {
        //background: $color-white;
        border-radius: 4px;
      }
    }
    &.book-mcr-expanded,
    &.book-mcr-highlighted {
      > .panel {
        border-left: solid $color-blue-primary !important;
        border-left-width: 6px;
        border-right: solid $color-blue-primary !important;
        border-right-width: 6px;
        padding-left: 0;
        padding-right: 0;
      }
    }
    &.book-mcr-expanded {
      > .panel {
        position: relative;
        z-index: unset;
        box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.4);
      }
    }
    &:first-child {
      margin-top: 0;
    }
    &:not(.book-mcr-selected):not(.book-mcr-expanded):not(.book-mcr-row-child):hover {
      > .panel {
        background: #d3e2ef;
      }
      > .dark-layout {
        background: #0D2936 !important;
      }
    }
    &.book-mcr-expanded:not(.book-mcr-selected) {
      > .panel {
        background: #d3e2ef;
      }
      > .dark-layout {
        background: #1e2a2f !important;
      }
    }
    .panel {
      margin-bottom: 0;
    }
    .book-mcr-group-label {
      // box-shadow: 0 3px 20px -8px rgba($color-shadow, 0.4);
      font-weight: bold;
      font-size: 1.1em;
      // margin-bottom: 5px;
      padding: 4px 0 4px 25px;
    }
  }
  .book-mcr-row-contributions {
    + .book-mcr-row-children {
      box-shadow: 0 -4px 6px -4px rgba(0, 0, 0, 0.2);
    }
  }
  .book-mcr-row-children {
    display: block;
    margin: -1px 43px 0 17px;
    position: relative;
    z-index: unset;
    &:last-child {
      margin-bottom: 10px;
    }
    // .book-mcr-column.book-mcr-toggle {
    //     width: 55px;
    // }
    .book-mcr-row-child {
      display: flex;
      flex-flow: row wrap;
      margin-top: -1px;
      position: relative;
      z-index: unset;
      &:hover,
      &.book-mcr-expanded {
        > .dark-layout {
          background: #0D2936 !important;
          color: white !important;
        }
        > .dark-layout-expand {
          background: #0D2936 !important;
          color: white !important;
        }
        > .list-group-item {
          background: #d3e2ef;
          color: black;
        }
        .book-mcr-column {
          z-index: 1;
        }
      }
      &:first-child {
        .list-group-item {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
      }
      &:last-child {
        &.book-mcr-row-with-children {
          .book-mcr-row-child {
            .list-group-item {
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }
        .list-group-item {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      &.book-mcr-expanded {
        + .book-mcr-row-child {
          box-shadow: 0 -4px 6px -4px rgba(0, 0, 0, 0.2);
          margin-top: -1px;
          .list-group-item {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
        > .list-group-item {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      &.book-mcr-row-with-children {
        overflow: hidden;
        > .list-group-item {
          box-shadow: 0 4px 6px -4px rgba(0, 0, 0, 0.2);
          z-index: unset;
        }
        .book-mcr-row-child {
          .list-group-item {
            border-radius: 0;
          }
        }
        .book-mcr-columns {
          position: relative;
        }
      }
      .book-mcr-columns {
        width: 100%;
      }
    }
    .list-group-item {
      min-height: 32px;
      padding: 0;
      &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  .book-mcr-row-contributions {
    .book-mcr-column-id {
      span {
        display: none;
      }
    }
    .list-group-item {
      background: darken($color-white, 2%);
    }
  }
  .book-mcr-row-associations {
    .book-mcr-row-contributions {
      margin: 0 70px;
    }
  }
  .book-mcr-labels {
    background: rgba($color-white, 0.95);
    font-weight: 700;
    margin-bottom: 5px;
    &.affix {
      border-radius: 0 0 4px 4px;
      box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.4);
      left: 15px;
      right: 15px;
      z-index: 1000;
      ~ .book-mcr-rows {
        margin-top: 36px;
      }
    }
    .book-mcr-column {
      align-items: center;
      display: flex;
      &.book-sortable {
        cursor: pointer;
        user-select: none;
      }
    }
    .book-mcr-toggle {
      button {
        width: 24px;
      }
    }
  }
  .book-mcr-labels,
  .book-mcr-rows {
    flex-basis: 100%;
  }
  .book-mcr-columns {
    align-items: stretch;
    display: flex;
    padding-left: 15px;
    &.updated {
      background: #b7b9454d !important;
    }
    &.renewed {
      background: #73d0bf52 !important;
    }
    &.renewed-dark {
      background: #3e654a !important;
    }
  }
  .panel > .book-mcr-columns > .book-mcr-column.book-mcr-toggle {
    max-width: 48px;
    min-width: 48px;
  }
  .panel > .book-mcr-columns > .book-mcr-column.book-mcr-attachment {
    max-width: 48px;
    min-width: 48px;
  }
  .book-mcr-column {
    align-items: center;
    display: flex;
    padding: 0 8px;
    &.book-mcr-column-grow {
      //flex: 1;
    }
    &.book-mcr-toggle {
      justify-content: flex-end;
      padding: 0 4px;
      width: 55px;
      .btn-mcr-toggle-contributions {
      }
      .btn-mcr-toggle-associations {
        margin-left: 3px;
        width: 38px;
      }
      .btn:focus {
        outline: none;
      }
    }
    &.active {
      //background: $color-item-hover;
    }
    &.grey {
      background: #949494 !important;
      color: $color-list-item-text-unsigned-priority;
    }
    &.red {
      background: $color-list-item-unsigned-priority;
      color: $color-list-item-text-unsigned-priority;
    }
    &.green {
      background: $color-list-item-signed;
      color: $color-list-item-text-unsigned-priority;
    }
    &.orange {
      background: $color-list-item-unsigned;
    }
    &.updated {
      background: $color-blue !important;
    }
    &.comment {
      background: #364a67 !important;
      color: white;
    }
    &.renewed {
      background: #62ff6047 !important;
    }
    &.renewed-dark {
      background: #3e654a !important;
    }
    &.book-mcr-equipment {
      min-width: 375px;
      width: 375px;
      flex: 1;
      white-space: normal;
      display: flex;
      overflow: hidden;
      .book-mcr-equipment-type,
      .book-mcr-equipment-piece {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 70px;
        position: relative;
        text-align: left;
        white-space: nowrap;
      }

      .book-mcr-equipment-type {
        max-width: 270px;
      }
      .book-mcr-equipment-type-label {
        font-weight: 600;
        margin-right: 3px;
        &:last-child {
          margin-right: 10px;
        }
      }
      .book-mcr-equipment-piece {
        max-width: 151px;
        vertical-align: middle;
        > .book-equipment-selection {
          min-height: 30px !important;
          max-height: 30px !important;
          > .book-box-overlay {
            cursor: pointer !important;
            z-index: unset !important;
            > .label {
              width: 95%;
              padding: 5px;
              margin-right: -3px;
            }
          }
        }
      }
    }
    &.book-mcr-status-column,
    &.book-mcr-ingestions,&.book-viaplay-column,&.book-tx-status-column {
      text-align: center;
    }
    &.book-mcr-status-column {
      width: 122px;
      margin-left: 10px;
    }
    &.book-mcr-ingestions {
      width: 230px;
    }
    &.book-viaplay-column {
      width: 37px;
    }
    &.book-tx-status-column {
      width: 88px;
    }
    &.book-mcr-actions {

      // @TODO The width whould be set dynamically...
      width: 85px;

      button {
        margin-right: 2px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    > .book-mcr-column-content {
      display: block;
      overflow: hidden;
      //text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
    [class*=book-item-status-] {
      > * {
        vertical-align: middle;
      }
      .book-square {
        display: inline-block;
        height: 12px;
        width: 12px;
        border-radius: 25px;
        margin-right: 5px;
        border: 0px solid #fff;
      }
      .label {
        display: inline-block;
        padding: 3px 6px 5px;
      }
      .book-square + .label {
        margin-left: 2px;
      }
    }
    .book-column-icon-wrapper {
      display: block;
      flex: 1;
      font-size: 18px;
      text-align: center;
    }
    .small-font {
      font-size: 13px !important;
    }
    .book-info-item {
      display: inline-block;
      + .book-info-item {
        border-left: 1px solid $color-text;
        margin-left: 5px;
        padding-left: 5px;
      }
    }
  }
  .book-marker {
    margin-right: 8px;
  }
}
.contribution-equipment {
  background-color: #004779 !important;
}
.contribution-equipment-dm {
  background-color: #001e33 !important;
}
.cov-vue-date > .datepicker-overlay {
  white-space: normal !important;
}
.mcr-comment-light {
  background-color: #cce3e7 !important;
}
.mcr-comment-dark {
  background-color: #0c1d20 !important;
}
.label-mcr-comment {
  background-color: #4a919d !important;
  margin-left: 19px;
}
.label-grey {
  background-color: #949494 !important;
}



.vue-dropzone:hover {
  background: unset !important
}
