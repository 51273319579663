
#login {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    .login-container {
        margin-top: -100px;
        max-width: 400px;
        width: 90%;
    }
    .book-logo {
        margin: 0 auto;
        text-align: center;
        width: 200px;
        img {
            display: inline-block;
        }
    }
    .book-sitename {
      text-align: center;
      margin: -7px 0 25px;
    }
    .book-loader {
        margin-bottom: 20px;
        position: relative;
        z-index: 1;
    }
    .book-content {
        margin-bottom: 0;
        margin-top: -45px;
        overflow: hidden;
        position: relative;
        z-index: 2;
        &.book-loading {
            margin-top: 0;
        }
    }
    .book-link-wrapper {
        display: block;
        margin-top: 10px;
        text-align: right;
        span{
            float: left;
        }
        a {
            cursor: pointer;
        }
    }
    .book-token-login {
        h3,
        h4 {
            line-height: 1.4;
            margin-bottom: 20px;
        }
        h3 {
          margin-top: 0;
        }
        h4 {
            &:first-child,
            &:last-child {
                margin: 0;
            }
        }
    }
}
