.btn-special {
    color: #fff;
    background-color: #ec5f27;
    border-color: #e75d27;
  }
  
  .btn-special:focus,
  .btn-special.focus {
    color: #fff;
    background-color: #ec5f27;
    border-color: #e75d27;
  }
  
  .btn-special:hover {
    color: #fff;
    background-color: #ec5f27;
    border-color: #e75d27;
  }
  
  .btn-special:active,
  .btn-special.active,
  .open > .btn-special.dropdown-toggle {
    color: #fff;
    background-color: #ec5f27;
    border-color: #e75d27;
  }
  
  .btn-special:active:hover,
  .btn-special:active:focus,
  .btn-special:active.focus,
  .btn-special.active:hover,
  .btn-special.active:focus,
  .btn-special.active.focus,
  .open > .btn-special.dropdown-toggle:hover,
  .open > .btn-special.dropdown-toggle:focus,
  .open > .btn-special.dropdown-toggle.focus {
    color: #fff;
    background-color: #ec5f27;
    border-color: #e75d27;
  }
  
  .btn-special:active,
  .btn-special.active,
  .open > .btn-special.dropdown-toggle {
    background-image: none;
  }
  
  .btn-special.disabled:hover,
  .btn-special.disabled:focus,
  .btn-special.disabled.focus,
  .btn-special[disabled]:hover,
  .btn-waspecialrning[disabled]:focus,
  .btn-special[disabled].focus,
  fieldset[disabled] .btn-special:hover,
  fieldset[disabled] .btn-special:focus,
  fieldset[disabled] .btn-special.focus {
    background-color: #ec5f27;
    border-color: #e75d27;
  }
  
  .btn-special .badge {
    color: #ec5f27;
    background-color: #fff;
  }
  
  .btn-plus {
    color: #fff;
    background-color: #e9e9e9;
    border-color: #e9e9e9;
  }
  
  .btn-special:focus,
  .btn-special.focus {
    color: #fff;
    background-color: #e4e1e1;
    border-color: #e4e1e1;
  }
  
  .btn-special:hover {
    color: #fff;
    background-color: #e4e1e1;
    border-color: #e4e1e1;
  }
  
  .btn-special:active,
  .btn-special.active,
  .open > .btn-special.dropdown-toggle {
    color: #fff;
    background-color: #e4e1e1;
    border-color: #e4e1e1;
  }
  
  .btn-special:active:hover,
  .btn-special:active:focus,
  .btn-special:active.focus,
  .btn-special.active:hover,
  .btn-special.active:focus,
  .btn-special.active.focus,
  .open > .btn-special.dropdown-toggle:hover,
  .open > .btn-special.dropdown-toggle:focus,
  .open > .btn-special.dropdown-toggle.focus {
    color: #fff;
    background-color: #e4e1e1;
    border-color: #e4e1e1;
  }
  
  .btn-special:active,
  .btn-special.active,
  .open > .btn-special.dropdown-toggle {
    background-image: none;
  }
  
  .btn-special.disabled:hover,
  .btn-special.disabled:focus,
  .btn-special.disabled.focus,
  .btn-special[disabled]:hover,
  .btn-waspecialrning[disabled]:focus,
  .btn-special[disabled].focus,
  fieldset[disabled] .btn-special:hover,
  fieldset[disabled] .btn-special:focus,
  fieldset[disabled] .btn-special.focus {
    background-color: #e4e1e1;
    border-color: #e4e1e1;
  }
  
  .btn-special .badge {
    color: #e4e1e1;
    background-color: #fff;
  }