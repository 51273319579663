

#user-profile {

  margin: 0 auto;
  //max-width: 600px;

  @media (min-width: 766px){
    .no-margin > [class*='form-'] {
      margin-bottom:0;
    }
  }
  
  @media (max-width:552px) {
    .xs-margin{
      margin-top: 55px;
    }
  }

  @media (max-width:522px) {
    .xs-margin{
      margin-top: 30px;
    }
  }

  td.book-label {
    text-align: right;
    width: 110px;
  }

  table {
    margin-bottom: 0;
  }

  td {
    border: none !important;
  }

  .book-left-column {
    
    width: 100%;
    .label-danger {
      display: block;
      font-size: 14px;
      margin-top: 10px;
    }
    .book-toggle {
      margin-top: 10px;
      width: 100%;
    }
  }

  .book-right-column {
    overflow: hidden;
  }

  .profile-photo-container{
    text-align: center;
    max-width: 300px;
    margin: 0 auto;

    .activate-button-wrapper{
      max-width: 50%;
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .activate-button-wrapper > div{
      width:100%;
    }
  }

  @media (max-width: 725px) {
    .profile-photo-container{
      max-width: 200px;
    }
  }

  @media (max-width: 776px) {
    .profile-view-container{
      margin-top: 20px;
    }
  }
  
}


