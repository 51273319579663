
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.autocomplete{
    position: relative;
    width: 100%;

    .autocomplete-results {
        padding: 0;
        margin: 0;
        border: 1px solid #eeeeee;
        height: 100%;
        min-height: 120px;
        max-height: 240px;
        overflow: auto;
        width: 100%;
        position: absolute;
        z-index: 9999;
        background-color: #ffffff;
    }

    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
    }

    .autocomplete-result.is-active {
        background-color: #1c7BB4;
        color: white;
    }
    .autocomplete-result:hover {
        background-color: #eeeeee;
        color: #333333;
    }
}
