

.book-cost-center-item {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}

.book-price-wrapper {
  clear: both;
  display: flex;
  padding-right: 15px;
  .control-label {
    margin-right: 15px;
    text-align: left;
  }
  .input-group {
    max-width: 150px;
  }
  .multiselect {
    margin-left: auto;
    max-width: 250px;
    .multiselect__tag {
      display: none;
    }
  }
}
.book-selected-cost-centers {
  margin-top: 15px;
  .book-cost-center-split {
    text-align: right;
    width: 80px;
  }
  .book-cost-center-actions {
    width: 38px;
  }
  .table {
    margin-bottom: 0;
  }
  td {
    vertical-align: middle !important;
  }
}

.book-billing-type-itemised {
  position: relative;
  &.book-cost-center-item {
    background: lighten(#000, 98.5%);
    border: 2px solid lighten(#000, 76.7%);
    border-radius: 4px;
    margin-right: 47px;
    padding: 15px;
    .book-remove-button {
      position: absolute;
      right: -47px;
      top: -2px;
    }
  }
}

