
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

// We apply this class to basically force Safari to re-render layout when item is closed.
.book-selectbox-open {
    min-width: 0.1vw;
}

.has-error {
    .book-selectbox .book-selectbox-selected .book-selectbox-selected-text {
        border-color: $color-red;
    }
    .form-control.book-selectbox-search {
        border-color: $color-form-control !important;
    }
}

.book-selectbox {
    .bttn-smaller {
      padding: 0px 3px;
    }
    overflow: hidden;
    position: relative;
    .book-selectbox-arrow-up {
        border-bottom: 8px solid $color-form-control;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        height: 0;
        position: absolute;
        right: 9px;
        top: -7px;
        width: 0;
    }
    .book-selectbox-selected,
    .book-selectbox-items-wrapper {
        position: relative;
    }
    .book-selectbox-selected {
        background: $color-white;
        display: flex;
        z-index: 2;
        .book-selectbox-selected-texts {
            border: 1px solid $color-form-control;
            border-right-width: 0;
            border-radius: 3px 0 0 3px;
            float: none !important;
            flex: 1;
            padding:6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.book-selectbox-selected-text-disabled {
                background-color: $color-grey-light;
                cursor: not-allowed;
            }
        }
        .book-selectbox-clean-button {
            background: none;
            border: none;
            color: $color-grey;
            margin: 2px 2px 2px -30px;
            outline: none;
            &:not([disabled]):hover {
                color: $color-text;
            }
        }
        .book-selectbox-selected-button {
            border-radius: 0 3px 3px 0;
            .book-loader {
                display: inline-block;
                vertical-align: calc(-2px);
                .cssload-container {
                    height: 12px;
                    .cssload-zenith {
                        height: 12px;
                        width: 12px;
                    }
                }
            }
        }
        .come-left {
            margin-left: -1px !important;
        }
    }
    .book-selectbox-selected-text {
        cursor: pointer;
    }
    .book-selectbox-items-wrapper {
        margin-top: 10px;
        z-index: 1;
        .cssload-container {
            margin-bottom: 15px;
            margin-top: 15px;
        }
    }
    .book-selectbox-search {
        border-radius: 3px 3px 0 0 !important;
        &:focus {
            border-color: $color-form-control;
        }
    }
    .book-selectbox-items,
    .book-selectbox-nothing-found {
        border: 1px solid $color-form-control;
        border-radius: 0 0 3px 3px;
        height: 130px;
        margin-top: -1px;
    }
    .book-selectbox-nothing-found {
        font-size: .9em;
        margin-bottom: 10px;
        padding: 20px 10px 10px;
        text-align: center;
    }
    .book-selectbox-items {
        border: 1px solid $color-form-control;
        border-radius: 0 0 3px 3px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        list-style: none;
        overflow-y: auto;
        padding: 0;
    }
    .dark-port {
      &:nth-child(even) {
        background: #333333 !important;
      }
    }
    .book-selectbox-item {
        border-left: 5px solid transparent;
        cursor: pointer;
        margin: 2px;
        padding: 5px 35px 5px 10px;
        position: relative;
        &:nth-child(even) {
            background: $color-grey-light-transparent;
        }
        &:first-child {
            margin-top: 2px;
        }
        &:last-child {
            margin-bottom: 2px;
        }
        &:hover:not(.book-selectbox-item-blocked) {
            background: $color-blue-primary !important;
            border-left-color: $color-blue-primary !important;
            * {
                color: $color-white !important;
            }
        }
      &:hover:not(.book-selectbox-item-passive) {
        background: $color-blue-primary !important;
        border-left-color: $color-blue-primary !important;
        * {
          color: $color-white !important;
        }
      }
        &.book-selectbox-item-selected {
            border-left-color: $color-blue-primary;
            background: rgba($color-blue-primary, .1);
            // color: $color-white;
            .book-selectbox-item-icon {
                color: $color-blue-primary;
            }
        }
        &.book-selectbox-item-blocked {
            border-left-color: $color-red !important;
            background: rgba($color-red, .1) !important;
            cursor: not-allowed;
            .book-selectbox-item-icon {
                color: $color-red;
            }
        }
        &.book-selectbox-item-warningz {
            background: $color-blue-primary !important;
            border-left-color: $color-blue-info !important;
            * {
                color: $color-white !important;
            }
        }
      &.book-selectbox-item-passive {
        border-left-color: $color-red !important;
        background: rgba($color-red, .1) !important;
        .book-selectbox-item-icon {
          color: $color-red;
        }
      }
        > * {
            display: block;
        }
        .book-selectbox-item-icon {
            font-size: 18px;
            height: 20px;
            line-height: 20px;
            margin-top: -10px;
            position: absolute;
            right: 7px;
            text-align: center;
            top: 50%;
            width: 20px;
        }
    }
  .book-selectbox-item-text {

  }
    .book-selectbox-channel-item-text {
      font-weight: bold;
    }
    .book-selectbox-item-extra {
        color: $color-grey;
        font-size: .9em;
    }
}
