
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/_variables.scss";

.book-equipment-wrapper {
    .panel-body {
        position: relative;
    }
    .book-button-add-equipment {
        position: absolute;
        right: 0;
        top: -27px;
        z-index: 10;
    }
    .panel-heading ~ .book-equipment-selection {
        .book-button-add-equipment {
            right: 3px;
            top: -25px;
        }
    }
}
.book-add-equipment-wrapper {
    border-bottom: 1px solid #DDDDDD;
    text-align: right;
    > *:not(.help-block) {
        @include breakpoint(min-width $min-tablet) {
            display: inline-block;
        }
    }
    .form-group {
        margin: 10px;
        > * {
            display: inline-block;
        }
        label {
            margin-right: 5px;
            width: calc(30% - 10px);
        }
        .form-control {
            width: 70%;
        }
        label,
        .form-control {
            @include breakpoint(min-width $min-tablet) {
                width: auto;
            }
        }
    }
}
.book-equipment-selection {
    min-height: 40px;
    position: relative;
    .modal-footer {
        .book-loader {
          float: left;
          margin-left: 5px;
          margin-top: 5px;
        }
    }
    .book-table {
        border: none;
        margin-bottom: 0;
    }
    .table {
        margin-bottom: 0;
    }
    > .book-loader {
        padding: 9px 0;
    }
    .book-help-message-conflict {
        line-height: 1.4em;
        text-align: left;
    }

    
}
.panel-body > .book-equipment-selection {
    margin: -15px;
}
.book-compact-panels .panel-body > .book-equipment-selection {
    margin: -8px;
}
.swal2-container {
    z-index: 10000 !important;
}
