
@import "../../style/_variables.scss";
.label-exceeded {
  background-color: $color-list-item-conflict-warning;
}
.book-width-60-percents {
  width: 60%;
}

.book-sidebar-crew-actions {
  margin-right: 8px;
}
