
@import "../../style/variables";

.audio-layout-print-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  .border-channel {
    border-top: 1px solid $color-grey-border;
  }
  .padding-top-5 {
    padding-top: 5px;
  }
}

.col-sm-6.channel-left {
  padding-right: 4px;
}

.col-sm-6.channel-left,
.col-sm-6.channel-right {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.book-sidebar-table-row.border-channel {
  border-top: 1px solid $color-grey-border;
}

.col-sm-12.audio-label,
.col-sm-6.audio-label {
  padding-left: 0;
  padding-right: 2px;
  font-size: 10px;
  font-weight: normal;

  .col-sm-12 {
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
  }

  .control-label {
    text-align: left;
    font-size: 10px;
    padding-top: 0;
  }

  .control-label.pair-label {
    font-size: 10px;
    padding-top: 0;
  }

  overflow: hidden;

  .radio-inline {
    padding-left: 0;
    padding-top: 0;


    input[type="radio"] {
      margin-left: 0;
      margin-top: 0;
    }

    span {
      margin-left: 15px;
      font-weight: normal;
      font-size: 8px;
    }
  }

  .radio-inline + .radio-inline {
    margin-left: 2px;
  }

}

.mono-wrapper {
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 8px;

  > .audio-pair {
    flex: 1;
  }
}

.audio-pair {
  input {
    width: 100%;
    font-size: 10px;
    padding: 1px;
    height: 30px;
    line-height: 30px;
  }
}


.col-sm-12.audio-pair,
.col-sm-6.audio-pair {
  padding-left: 0;
  padding-right: 0;

  .col-sm-12 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .col-sm-6.channel-left {
    padding-right: 15px;

  }
  .col-sm-6.channel-right {
    padding-top: 8px;
    padding-left: 15px;


  }

  .col-sm-6.audio-label {
    .radio-inline {
      padding-top: 3px;

      span {
        font-size: 10px;
      }
    }

    .control-label.pair-label {
      padding-top: 8px;
    }

    .control-label {
      font-size: 12px;
    }

    .control-label.pair-label {
      font-size: 12px;
    }
  }

  .col-sm-6.audio-pair {
    select {
      font-size: 12px;
      padding: 0;
    }
  }
}

@media (min-width: 1300px) {
  .col-sm-6.channel-left,
  .col-sm-6.channel-right {
    .col-sm-6.audio-label {
      width: 105px;
    }

    .col-sm-6.audio-pair {
      width: calc(100% - 105px);
    }

  }

  .col-lg-6.audio-label {
    width: 105px;
  }

  .col-lg-6.audio-pair {
    width: calc(100% - 105px);
  }

}

.book-sidebar-table-row-nowrap.book-width-40-percents {
  width: 40%;
}

.book-width-60-percents {
  width: 60%;
}
