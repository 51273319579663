/**
DarkMode - WN
*/
.dark-bg {
background: #000 !important;
border: 1px solid #333333 !important;
}
.dark-box {
color: black;
}
.dark-layout {
background-color: #424242 !important;
border-color: #656565 !important;
}
.dark-layout-filters {
background-color: #000000 !important;
border-color: #656565 !important;
> button {
    opacity: 0.8;
}
> div {
    border-color: #333333 !important;
}
}
.dark-layout-affix {
background-color: #7d7d7d !important;
border-color: #656565 !important;
}
.dark-layout-header {
background-color: #000 !important;
border-color: #656565 !important;
}
.dark-layout-expand {
background-color: #2b2b2b !important;
border-color: #656565 !important;
}
.dark-input {
    > button {
    border: 2px solid black !important;
    background-color: #1d1d1d !important;
}
    > input {
    color: white !important;
    background-color: #1d1d1d !important;
    border: 2px solid black !important;
}
> select {
    color: #fff !important;
    background-color: #000 !important;
    border: 1px solid #3e3e3e !important;
}
}

button.dark-second-input {
color: #fff !important;
background-color: #000 !important;
border: 1px solid #3e3e3e !important;
}
.dark-second-input {
> button {
    color: #fff !important;
    background-color: #000 !important;
    border: 1px solid #3e3e3e !important;
}
}

.dark-border {
border-right: 1px solid black;
}
.secondary-menu.dark-input > * > button, .secondary-menu.dark-input > * > * > button {
color: #fff !important;
background-color: #000 !important;
border: 1px solid #3e3e3e !important;
}
.secondary-menu.dark-input > .book-date-navigation > * > button:hover {
background-color: white;
color: black;
}
.secondary-menu.dark-input > .book-date-navigation > * > .cov-vue-date.date-navigation > * {
    > input {
    color: white !important;
    background-color: #1d1d1d !important;
    border: 1px solid #3e3e3e !important;
    }
}
.book-selectbox-selected.dark-port {
background: #000 !important;
}
// modal
.modal-mask.dark-port {
> div > div {
    background-color: #424242 !important;
    border-color: #656565 !important;
    > div > button {
    color:white !important;
    opacity: 1;
    }
    .modal-body > div > div > * {
    > label {
        background-color: #424242 !important;
    }
    > div.form-control-100 {
        background-color: #424242 !important;
    }
    > * {
        background-color: black !important;
        > div {
        background-color: black !important;
        }
    }
    }
    .modal-footer > div > button {
    border: 2px solid black !important;
    background-color: #1d1d1d !important;
    }
}
}

.modal-mask.dark-equipment-modal {
// dropzone
.dropzone .dz-preview.dz-success {
    transition: unset !important;
    background-color: #2d2d2d !important;
    border-color: #545454 !important;

}
> div > div {
    background-color: #424242 !important;
    border-color: #656565 !important;
    > div > button {
    color:white !important;
    opacity: 1;
    }
}
.modal-body > div > div > div > div {
    > select {
    color: #fff;
    background-color: #000;
    border: 1px solid #3e3e3e !important;
    }
    > select:disabled {
    color: #fff;
    background-color: #2b2b2b;
    border: 1px solid #3e3e3e !important;
    }
    > div {
    > input {
        color: white !important;
        background-color: #1d1d1d !important;
        border: 1px solid #3e3e3e !important;
    }
    > span > button {
        color: #fff !important;
        background-color: #000 !important;
        border: 1px solid #3e3e3e !important;
    }
    }
}
.modal-footer > div > button {
    border: 2px solid black !important;
    background-color: #1d1d1d !important;
}
}
//columns
.column-passive-dark {
color: black !important;
}
.column-passive-light {
color: #c2c2c2 !important;
}
.give-pointer {
cursor: pointer !important;
}
.give-dark {
background-color: black !important;
}
.bg-warning-dark {
background-color: #003238 !important;
}
.danger-dark { // conflict & exceeds & highlight <book-table>
background-color: #8c000066 !important;
}
// dropzone

.darkMode {
> div {
    //background-color: black !important;
    color: white !important;
}
}