.page-header {
    align-items: center;
    border-bottom: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    margin-top: 0;
    padding-top: 30px;
    text-align: center;
    overflow-x: auto;
    @include breakpoint(min-width $min-tablet) {
        align-items: center;
        margin-bottom: 25px;
        text-align: left;
        overflow-x: visible;
    }

    > * {
        flex-shrink: 0;
    }
}
.page-title {
    flex: 1;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    @include breakpoint(min-width $min-tablet) {
        text-align: left;
    }
}
.book-top-sticky {
    .page-title {
        font-size: 20px;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include breakpoint(min-width $min-tablet) {
            font-size: 28px;
            line-height: 34px;
        }
    }
}
.primary-menu,
.secondary-menu {
    @include breakpoint(min-width $min-tablet) {
        margin-top: 1px;
    }
    .item-label {
        display: none;
        @include breakpoint(min-width $min-tablet) {
            display: inline-block;
        }
    }
}
.primary-menu {
    flex: 1;
}
.secondary-menu {
    margin-left: 10px;
    text-align: center;
    @include breakpoint(min-width $min-tablet) {
        margin-left: 10px;
        margin-top: 1px;
        text-align: left;
    }
    + .secondary-menu {
        @include breakpoint(min-width $min-tablet) {
            margin-left: 10px;
            margin-top: 1px;
        }
    }
}
