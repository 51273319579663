
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
@import "../../style/darkmode.scss";

.book-placeholder-wrapper {
  .panel-body {
    padding: 0;
    position: relative;
  }
  .book-button-add-placeholder {
    position: absolute;
    right: 0;
    top: -27px;
    z-index: 10;
  }
  .panel-heading ~ .book-fork-placeholder {
    .book-button-add-placeholder {
      right: 3px;
      top: -25px;
    }
  }
}
.book-add-equipment-wrapper {
  border-bottom: 1px solid #DDDDDD;
  text-align: right;
  > *:not(.help-block) {
    @include breakpoint(min-width $min-tablet) {
      display: inline-block;
    }
  }
  .form-group {
    margin: 10px;
    > * {
      display: inline-block;
    }
    label {
      margin-right: 5px;
      width: calc(30% - 10px);
    }
    .form-control {
      width: 70%;
    }
    label,
    .form-control {
      @include breakpoint(min-width $min-tablet) {
        width: auto;
      }
    }
  }
}
.book-fork-placeholder {
  min-height: 40px;
  position: relative;
  .book-button-add-placeholder-modal {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 10;
  }
  .modal-footer {
    .book-loader {
      float: left;
      margin-left: 5px;
      margin-top: 5px;
    }
  }
  .book-table {
    border: none;
    margin-bottom: 0;
  }
  .table-modal {
      margin-top: 10px;
  }
  .table {
    margin-bottom: 0;
  }
  > .book-loader {
    padding: 9px 0;
  }
  .book-help-message-conflict {
    line-height: 1.4em;
    text-align: left;
  }

  .book-overview-item-title > span  {
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.panel-body > .book-fork-placeholder {
  margin: -15px;
}
.book-compact-panels .panel-body > .book-fork-placeholder {
  margin: 0px;
}
.swal2-container {
  z-index: 10000 !important;
}
