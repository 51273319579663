
.book-signoff {
    .book-signoff-row {
        align-items: center;
        display: flex;
        margin-bottom: 10px;
        min-height: 38px;
        position: relative;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .book-signoff-overlay {
        background: rgba(#FFF, 0.6);
        bottom: 0;
        cursor: not-allowed;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }
    .book-toggle,
    .book-loader,
    .book-signoff-label-wrapper {
        position: relative;
    }
    .book-toggle,
    .book-signoff-label-wrapper {
        z-index: 1;
    }
    .book-loader {
        margin-right: 10px;
        z-index: 3;
    }
    .book-signoff-label-wrapper {
        align-items: center;
        display: flex;
        flex: 1;
        flex-flow: row wrap;
    }
    .book-signoff-label,
    .book-signoff-user {
        display: block;
        flex-basis: 100%;
    }
    .book-signoff-label {
        font-weight: 600;
    }
    .book-signoff-user {
        font-size: 0.95rem;
    }
}
