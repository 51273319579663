
@import "../../style/_variables.scss";
.book-timeline {
    display: flex;
    overflow: hidden;
    .book-timeline-labels {
        width: 125px;
        z-index: 3;
    }
    .book-timeline-labels-svg {
        width: 100%;
    }
    .book-timeline-content {
        flex: 1;
        overflow: scroll;
        margin: 0;
        padding: 0 2px 0 0;
        position: relative;
        z-index: 2;
    }
    .book-timeline-content-svg {
        min-width: 905px;
        position: relative;
        width: 100%;
        z-index: 3;
    }
    .book-timeline-hour-column-line-fixed {
        stroke: darken($color-grey-light, 3%);
        stroke-width: 1px;
    }
    .book-timeline-hour-column-line {
        stroke: darken($color-grey-light, 3%);
        stroke-width: 1px;
    }
    .book-timeline-hour-column-text {
        fill: rgba($color-text, .7);
        font-size: 10px;
    }
    .book-timeline-marker,
    .book-timeline-marker2 {
        transition: all .3s ease;
    }
    @keyframes marker {
        to {
            width: 100%;
        }
    }
    .book-timeline-marker {
        animation: marker linear 1;
        fill: rgba($color-blue, .1);
        z-index: 1;
    }
    @keyframes marker2 {
        to {
            x: 100%;
        }
    }
    .book-timeline-marker2 {
        animation: marker2 linear 1;
        fill: $color-blue;
        width: 2px;
        z-index: 3;
    }
    .book-timeline-group {
        cursor: pointer;
        // &.book-timeline-odd:not(.book-timeline-single-group) {
        &.book-timeline-odd {
            .book-timeline-group-bkg {
                fill: rgba($color-blue, .1);
            }
        }
        .book-timeline-group-bkg {
            fill: transparent;
            width: 100%;
        }
    }
    .book-timeline-group-item-bkg {
        stroke: lighten($color-grey-light, 2%);
        stroke-width: 1px;
    }
    .book-timeline-group-item-text {
        font-size: 12px;
        overflow: hidden;
    }
    .book-timeline-no-results {
        align-self: center;
        flex: 1;
        padding: 10px;
        text-align: center;
    }
    .book-timeline-crew-list{
        background-color: #fff;
        margin-top: 5px;
        font-size: 10px;
    }
    .book-item-status-7{
        color:  $color-list-item-conflict-warning;
    }
    .book-item-status-5{
        color:  $color-list-item-option;
    }
    .book-item-status-4{
        color:  $color-list-item-unsigned;
    }
    .book-item-status-3{
        color:  $color-list-item-unsigned-priority;
    }
    .book-item-status-2{
        color:  $color-list-item-signed;
    }
    .book-item-status-1{
        color:  $color-list-item-passed;
    }
    .crew-item-skill{
        color: #000000;
        margin-left: 10px;
    }
    .crew-item-skill-no-margin{
        color: #000000;
    }
    .crew-item-row{
        min-width: 250px;
    }
    .selectbox-style {
        position: absolute;
        z-index: 10;
        margin-left: 20px;
        margin-top: 3px;
        margin-right: -75px;
        border: 1px solid #CCC;
        border-radius: 3px 0 0 3px;
        float: none !important;
        -ms-flex: 1;
        flex: 1;
        padding: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .hour-column-fixed {
        position: fixed;
        height: 30px;
        background-color: #eaeaea;
        z-index: 10;
    }
}
