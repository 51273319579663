
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

#crewviewmain {
    // .book-date-navigation {
    //     float: right;
    // }
    // .btn-navigation {
    //     margin-top: 5px;
    // }
    // .panel-default {
    //     margin-top: 27px;
    // }

    .book-square {
        border-radius: 3px;
        display: inline-block;
        height: 10px;
        width: 10px;
    }

    .panel-split {
        top: 0;
    }

    .full-size-menu {
        width: 100%;
    }

    .col-menu-1 {
        //padding-left: calc(100% / 12 * 4 - 234px);
        padding-left: 0px;
        padding-right: 0px;
        text-align: left;
    }

    .col-menu-2 {
        //padding-left: calc(100% / 12 * 4 - 287px);
        padding-left: 0px;
        padding-right: 0px;
        text-align: right;
    }

    .col-menu-3 {
        text-align: center;
    }

    .col-no-padding {
        padding-left: 0px;
        padding-right: 0px;
    }

    .col-padding-right {
        padding-left: 0px;
        padding-right: 0px;
    }

    .col-padding-left {
        padding-left: 8px;
        padding-right: 0px;
    }

    .col-apply {
        //width: 121px;
        text-align: center;
    }
    .crew-apply {
        
    }

    .crew-split,
    .col-scroll-enabled {
        //position: absolute;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .flex-fill {
        //flex:1;
    }

    .fixed-panel {
        //margin: 0;
        //overflow: hidden;
        // height: 100%;
    }

    .theme-switch-wrapper-in-btn-group {
        border: 1px solid #ccc;
        padding-top: 6px;
        padding-bottom: 6px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        float: left;
        margin-left: -1px;
    }
}

