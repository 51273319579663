

.animate {
  transition: all .2s ease;
}

// fade.

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}


// Switch.

.switch-enter-active,
.switch-leave-active {
  transition: all 100ms ease;
}
.switch-enter,
.switch-leave-to {
  opacity: 0;
  transform: scale(.98, .98);
}
.switch-enter-to,
.switch-leave {
  opacity: 1;
  transform: scale(1, 1);
}



// expand.

.expand-enter-active {
  transition: all .2s ease;
}
.expand-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.expand-enter,
.expand-leave-to {
  opacity: 0;
  transform: scale(0.2, 0.2);
}
.expand-enter-to,
.expand-leave {
  opacity: 1;
  transform: scale(1, 1);
}


// slide-fade-marker.

// .slide-fade-marker-enter-active,
// .slide-fade-marker-leave-active {
//   transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
// }
// .slide-fade-marker-enter,
// .slide-fade-marker-leave-to {
//   opacity: 0;
//   transform: translateX(-100%);
// }
// .slide-fade-marker-enter-to,
// .slide-fade-marker-leave {
//   opacity: 1;
//   transform: translateX(0);
// }


// slide-fade.

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(-100%);
  // transform: translateY(-100%);
  // transform: scale(0.9, 0.9);
}
.slide-fade-leave-to {
  transform: translateX(100%);
  // transform: scale(1.1, 1.1);
}

// slide-down-right-fade.

.slide-down-right-fade-enter-active {
  transition: all .3s ease;
}
.slide-down-right-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-down-right-fade-enter,
.slide-down-right-fade-leave-to {
  opacity: 0;
}
.slide-down-right-fade-enter {
  transform: translateY(-200%);
  // transform: translateY(-100%);
  // transform: scale(0.9, 0.9);
}
.slide-down-right-fade-leave-to {
  transform: translateX(100%);
  // transform: scale(1.1, 1.1);
}

// slide-up-fade.

.slide-up-fade-enter-active {
  transition: all .5s ease;
}
.slide-up-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-up-fade-enter,
.slide-up-fade-leave-to {
  opacity: 0;

}
.slide-up-fade-enter,
.slide-up-fade-leave-to {
  transform: translateY(-100%);
}

// slide-down-fade.

.slide-down-fade-leave,
.slide-down-fade-leave-active,
.slide-down-fade-leave-to {
  left: 0;
  position: absolute !important;
  right: 0;
  top: 35px;
}

.slide-down-fade-enter-active {
  transition: all .3s ease;
}
.slide-down-fade-leave-active {
  overflow: hidden;
  // transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-down-fade-enter,
.slide-down-fade-leave-to,
.slide-down-fade-leave-active {
  opacity: 0;
}

// .slide-down-fade-leave-to,
.slide-down-fade-enter {
  // transform: translateY(-100%);
}
