@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/_variables.scss";

#feed-edit-print {
    .print-page{
        background: white;
        width: 21cm;
        height: 29.7cm;
        display: block;
        margin: 0 auto;
        margin-bottom: 0.5cm;
        box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
    }
    .book-print-feed-audio-layout {
        .book-print-feed-audio-layout-isdn {
            width: 75px;
        }
    }
    .book-print-feed-encryption {
        .book-print-feed-encryption-country,
        .book-print-feed-encryption-value {
            display: block;
        }
        .book-print-feed-encryption-country {
            float: left;
        }
        .book-print-feed-encryption-value {
            overflow: hidden;
            padding: 1px 0 0 10px;
        }
        .book-print-feed-encryption-local {
            + .book-print-feed-encryption-local {
                margin-top: 5px;
            }
        }
    }
    .book-print-feed-local-notes {
        .book-print-feed-local-note-country,
        .book-print-feed-local-note-value {
            display: block;
        }
        .book-print-feed-local-note-country {
            float: left;
        }
        .book-print-feed-local-note-value {
            overflow: hidden;
            padding: 1px 0 0 10px;
        }
        .book-print-feed-local-note {
            + .book-print-feed-local-note {
                margin-top: 5px;
            }
        }
    }
}


#feeds {

    .book-table {
        .book-mcr-column {
            text-transform: uppercase;
        }
    }

}

td .book-event-name-part {
    border-right: 1px solid $color-text;
    display: inline-block;
    margin-right: 5px;
    padding-right: 5px;
    &:last-child {
        border-right: none;
        margin-right: 0;
    }
}
