
.book-loader {
    &.overlay {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 100001;
        &.modal {
            background: rgba(#FFF, .8);
        }
    }
}
.cssload-container {
  	width: 100%;
  	height: 46px;
    margin: 40px 0;
  	text-align: center;
    &.small {
        height: 20px;
        margin: 0;
        .cssload-zenith {
            box-shadow: 2px 2px 1px rgba(0,0,0,0.51);
            height: 20px;
            width: 20px;
            &.cssload-zenith-white {
                box-shadow: 2px 2px 1px rgba(255,255,255,0.71);
            }
        }
    }
}
.cssload-zenith {
  	width: 46px;
  	height: 46px;
  	margin: 0 auto;
  	border-radius: 50%;
  	border-top-color: transparent;
  	border-left-color: transparent;
  	border-right-color: transparent;
  	box-shadow: 3px 3px 1px rgba(0,0,0,0.51);
  	animation: cssload-spin 870ms infinite linear;
		-o-animation: cssload-spin 870ms infinite linear;
		-ms-animation: cssload-spin 870ms infinite linear;
		-webkit-animation: cssload-spin 870ms infinite linear;
		-moz-animation: cssload-spin 870ms infinite linear;
    &.cssload-zenith-white {
        box-shadow: 3px 3px 1px rgba(255,255,255,0.71);
    }
}
@keyframes cssload-spin {
	 100%{ transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes cssload-spin {
	 100%{ -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes cssload-spin {
	 100%{ -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@-webkit-keyframes cssload-spin {
	 100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes cssload-spin {
	 100%{ -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
