
.book-phone {
    .book-phone-wrapper {
        align-items: center;
        display: flex;
    }
    .book-phone-plus-sign,
    .book-phone-code {
        margin-right: 5px;
    }
    .book-phone-code {
        width: 60px !important;
    }
    .book-phone-partial {
        flex: 1;
    }
}
