
@import "../../../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/variables";

#book-equipment {
    .book-equipment {
        align-items: center;
        flex-flow: column;
        max-width: 100%;
        overflow-x: auto;
        .book-equipment-day-navigation,
        .book-equipment-content {
            min-width: 800px;
            width: 100%;
        }
        .book-equipment-day-navigation {
            &.affix {
                background: $color-white;
                border: 1px solid $color-grey-border;
                border-bottom-width: 0;
                position: fixed;
                left: 15px;
                width: calc(100% - 30px);
                z-index: 1000;
            }
            .book-equipment-label,
            .book-equipment-column {
                border-bottom: 1px solid $color-grey-border;
            }
            .book-equipment-column {
                padding: 2px 4px;
                &.book-equipment-today {
                    border-bottom-width: 1px;
                    border-top-width: 2px;
                }
            }
            .book-equipment-day {
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .book-equipment-week {
                background: $color-blue;
                // bottom: -4px;
                color: $color-white;
                // display: flex;
                // flex-direction: row;
                font-size: 12px;
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
                padding-top: 2px;
            }
            .book-equipment-week-empty {
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
            }
            //.book-equipment-week
            .book-equipment-weekday,
            .book-equipment-date {
                display: block;
            }
        }
        .book-equipment-day-navigation-split {
            &.affix {
                background: $color-white;
                border: 1px solid $color-grey-border;
                border-bottom-width: 0;
                position: fixed;
                left: 15px;
                width: calc(100% / 12 * 5 - 31px);
                z-index: 1000;
            }
            .book-equipment-label {
                display: inline-block;
            }
            .book-equipment-label,
            .book-equipment-column {
                border-bottom: 1px solid $color-grey-border;
            }
            .book-equipment-column {
                padding: 2px 4px;
                &.book-equipment-today {
                    border-bottom-width: 1px;
                    border-top-width: 2px;
                }
            }
            .book-equipment-day {
                font-weight: bold;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .book-equipment-week {
                background: $color-blue;
                color: $color-white;
                font-size: 12px;
                height: 20px;
                font-weight: bold;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
                padding-top: 2px;
            }
            .book-equipment-week-empty {
                height: 20px;
                font-weight: bold;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
            }
            .book-equipment-weekday,
            .book-equipment-date {
                display: block;
            }
        }
        .affix + .book-equipment-content {
            margin-top: 56px;
        }
        .book-equipment-content {
            .book-equipment-column {
                padding: 2px 0;
            }
        }
        .book-equipment-day-navigation,
        .book-equipment-row {
            display: flex;
        }
        .book-equipment-row {
            min-height: 38px;
            &:last-child {
                .book-equipment-label,
                .book-equipment-column:not(.book-equipment-today) {
                    border-bottom: 1px solid darken($color-grey-light, 3%);
                }
            }
            &.book-equipment-odd {
                background: rgba($color-blue, .1);
            }
        }
        .book-equipment-label {
            display: block;
            background: rgba($color-row-odd, .1);
            border-right: 1px solid darken($color-grey-light, 3%);
            max-width: 130px;
            min-width: 130px;
            overflow: hidden;
            padding: 8px 5px 6px;
            text-overflow: ellipsis;
            @include breakpoint(min-width $min-desktop) {
                max-width: 130px;
                min-width: 130px;
                padding-left: 5px;
                padding-right: 5px;
            }
            > span {
                user-select: none;
            }
        }
        .book-equipment-row-content {
            display: flex;
            flex: 1;
            min-width: 0;
        }
        .book-equipment-column {
            border-right: 1px solid darken($color-grey-light, 3%);
            flex: 1;
            min-width: 0;
            position: relative;
            z-index: 1;
            &:last-child {
                border-right: none;
            }
            &.book-equipment-today {
                border: 0 solid $color-blue;
                border-width: 0 2px;
                z-index: 2;
            }
        }

        .book-equipment-label,
        .book-equipment-item {
            font-size: 12px;
            line-height: 1.3em;
        }
        .book-equipment-item {
            cursor: pointer;
            margin: 2px 4px;
            padding: 4px 5px 3px;
            user-select: none;
        }
        .book-equipment-item-title {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .book-equipment-item-title {
            display: block;
        }
        .book-equipment-item-time {
            flex: 1 0 auto;
            display: inline-block;
        }
        .book-equipment-no-results {
            align-items: center;
            align-self: center;
            display: flex;
            flex: 1;
            padding: 10px;
            text-align: center;
        }
        .book-equipment-item-plus {
            height: 20px;
            font-weight: bold;
            position: relative;
            text-align: center;
            > * {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            margin: 2px 4px 6px;
            text-align: center;
        }
        .btn-plus {
            padding: 0px;
            width: 100%;
        }

        .book-equipment-item-actions {
            position: relative;
        }
    }
}

.book-equipment-action-modal-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}
