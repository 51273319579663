
.book-no-item-found {
    margin: 0;
    min-height: 38px;
    padding: 2rem 0;
    text-align: center;
}
.sortable-ghost {
    background: $brand-primary !important;
    * {
        color: #FFF !important;
    }
    .action {
        * {
            display: none;
        }
    }
    .grab-item {
        color: #000;
        cursor: grabbing;
    }
}

.grab {
  width: 50px;
  .grab-item {
    color: #D2D2D2;
    cursor: grab;
    display: inline-block;
    line-height: 30px;
    &:hover {
      color: #A2A2A2;
    }
  }
}

.table-fixed-layout {
  table-layout: fixed;
}

td.action {
  text-align: right;
  white-space: nowrap;
}
tr.cell-vertical-align-middle {
  td {
    vertical-align: middle !important;
  }
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 4px 8px;
}

.table {
    &.table-no-lines > tbody > tr > td {
        border-top: none;
        &:first-child,
        &:last-child {
            // padding-left: 0;
        }
    }
    &.table-no-paddings > tbody > tr > td {
        padding: 0;
    }
}
