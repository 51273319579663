
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
.clean-list{
    text-align: right;
    
    .clean-button, select-all-button{
        cursor: pointer;
    }
}
.select-all-icon {
    position: absolute;
    top: 6px;
    right: 33px;
}
.select-all-icon-a {
    top: 18px;
    right: 8px;
    margin-top: -18px;
    cursor: pointer;
    position: absolute !important;
    z-index: 1000;
}

.book-select-dropdown {
    &.book-select-dropdown-inplace {
        .dropdown-menu {
            box-shadow: none;
            float: none;
            position: relative;
        }
    }
    &.book-select-dropdown-always-visible {
        .dropdown-menu {
            display: block !important;
            max-height: 214px;
        }
    }
    .dropdown-menu {
        max-height: 514px;
        overflow-y: auto;
        .divider {
           margin: 3px 0;
        }
    }
    .dropdown-toggle {
        &:disabled {
            cursor: not-allowed;
        }
    }
    .dropdown-item {
        color: $color-text;
        &:hover {
            background: $color-item-hover;
            color: $color-menu-text-hover;
        }
        label,
        .book-select-dropdown-clear,
        input[type="radio"],
        input[type="checkbox"] {
            cursor: pointer;
        }
        label,
        .book-select-dropdown-clear {
            padding: 3px 10px 4px;
        }
        .book-select-dropdown-clear {
            font-weight: 600;
        }
        label {
            font-weight: 400;
            display: block;
            margin-bottom: 0;
            padding: 3px 10px 4px;
        }
        input[type="radio"],
        input[type="checkbox"] {
            margin-right: 10px;
            vertical-align: calc(1px);
        }
    }
}
