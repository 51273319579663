
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.panel > .book-overview {
  margin-bottom: -1px;
}

.book-overview-sticky {
  position: sticky;
  top: 0;
  z-index: 3;
  background: #fff;
  min-width: 800px;
  width: 100%;
}

.book-overview {
  align-items: center;
  flex-flow: column;
  max-width: 100%;
  overflow-x: auto;
  .book-overview-day-navigation,
  .book-overview-content {
    min-width: 800px;
    width: 100%;
  }
  .book-overview-day-navigation {

    .book-overview-label,
    .book-overview-column {
      border-bottom: 1px solid $color-grey-border;
    }
    .book-overview-column {
      padding: 2px 4px;
      &.book-overview-with-week {
        .book-overview-day {
          padding-left: 25px;
        }
      }
      &.book-overview-today {
        border-bottom-width: 1px;
        border-top-width: 2px;
      }
    }
    .book-overview-day {
      font-weight: bold;
      padding: 5px 7px;
      position: relative;
      text-align: center;
      > * {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .book-overview-week {
      background: $color-blue;
      bottom: -4px;
      color: $color-white;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      justify-content: center;
      left: -4px;
      line-height: 1.1em;
      position: absolute;
      top: -2px;
      width: 25px;
      > * {
        display: block;
      }
    }
    .book-overview-weekday,
    .book-overview-date {
      display: block;
    }
    .book-overview-weekday {

    }
  }
  .affix + .book-overview-content {
    margin-top: 56px;
  }
  .book-overview-content {
    .book-overview-column {
      padding: 2px 0;
    }
  }
  .book-overview-day-navigation,
  .book-overview-row {
    display: flex;
  }
  .book-overview-row {
    min-height: 38px;
    &:last-child {
      .book-overview-label,
      .book-overview-column:not(.book-overview-today) {
        border-bottom: 1px solid darken($color-grey-light, 3%);
      }
      .book-overview-column {
        &.book-overview-today {
          border-bottom-width: 2px;
        }
      }
    }
    &.book-overview-odd {
      background: rgba($color-blue, .1);
    }
  }
  .book-overview-label {
    background: rgba($color-row-odd, .1);
    border-right: 1px solid darken($color-grey-light, 3%);
    max-width: 70px;
    min-width: 70px;
    overflow: hidden;
    padding: 8px 6px 6px;
    text-overflow: ellipsis;
    @include breakpoint(min-width $min-desktop) {
      max-width: 100px;
      min-width: 100px;
      padding-left: 12px;
      padding-right: 12px;
    }
    > span {
      user-select: none;
    }
  }
  .book-overview-row-content {
    display: flex;
    flex: 1;
    min-width: 0;
  }
  .book-overview-column {
    border-right: 1px solid darken($color-grey-light, 3%);
    flex: 1;
    min-width: 0;
    position: relative;
    z-index: 1;
    &:last-child {
      border-right: none;
    }
    &.book-overview-today {
      border: 0 solid $color-blue;
      border-width: 0 2px;
      margin-left: -1px;
      z-index: 2;
      .book-overview-week {
        bottom: -2px;
      }
    }
  }
  .book-overview-label,
  .book-overview-item {
    font-size: 12px;
    line-height: 1.3em;
  }
  .book-overview-item {
    cursor: pointer;
    margin: 2px 4px;
    padding: 4px 5px 3px;
    user-select: none;
  }
  .book-overview-item-top-row {
    display: flex;
    font-size: 10px;
  }
  .book-overview-item-flag,
  .book-overview-item-league,
  .book-overview-item-driver,
  .book-overview-item-location,
  .book-overview-item-title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .book-overview-item-time,
  .book-overview-item-league,
  .book-overview-item-driver,
  .book-overview-item-location,
  .book-overview-item-flag {
    display: inline-block;
  }
  .book-overview-item-title {
    display: block;
  }
  .book-overview-item-time {
    flex: 1 0 auto;
  }
  .book-overview-item-flag {
    flex-shrink: 1;
    &:before {
      display: inline-block;
      content: "|";
      margin: 0 3px;
    }
  }
  .book-overview-item-location {
    flex-shrink: 1;
    //&:before {
    //  display: inline-block;
    //  content: "|";
    //  margin: 0 3px;
    //}
  }
  .book-overview-item-league, .book-overview-item-driver {
    flex: 30 1 auto;
    flex-shrink: 1;
    &:before {
      display: inline-block;
      content: "|";
      margin: 0 3px;
    }
  }
  .book-overview-no-results {
    align-items: center;
    align-self: center;
    display: flex;
    flex: 1;
    padding: 10px;
    text-align: center;
    // > * {
    //     display: flex;
    // }
  }
}
