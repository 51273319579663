

// *****************************************************************************
// Datepicker.
// @TODO The datepicker needs more custom style, according to the site style.

.cov-vue-date {
    .datepicker-overlay {
        z-index: 1040;
    }
    .cov-date-body {
        background: $color-blue-primary !important;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    }
    .cov-date-previous,
    .cov-date-next {
        &:hover {
            background: $color-blue !important;
        }
    }
    .cov-picker-box {
        height: auto !important;
        padding-bottom: 4px !important;
    }
    .checked {
        background: $color-blue-primary !important;
    }
}


// *****************************************************************************
// Multiselect.

.multiselect,
.multiselect__input,
.multiselect__single {
    font-size: 12px;
}
.form-group-sm {
    .multiselect {
        min-height: 32px;
    }
}
.multiselect.multiselect--active {
    z-index: 10;
}
.multiselect__content-wrapper {
    display: block;
    z-index: 100;
}
.multiselect__tags,
.multiselect__content {
    border-color: $input-border;
}
.multiselect__tags {
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    min-height: 34px;
    padding-top: 5px;
}
.form-group-sm {
    .multiselect__tags {
        min-height: 32px;
    }
}
.multiselect__tag,
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
    background: $brand-primary;
}
.multiselect__option--highlight,
.multiselect__option--highlight:after {
    background: $dropdown-link-hover-bg;
    color: $dropdown-link-hover-color;
}
.multiselect__tag {
    margin-bottom: 4px;
    margin-right: 6px;
}
.multiselect__option--highlight:after {
    font-size: 0.8em;
    font-weight: 400;
    opacity: 0.8;
}
.multiselect__option {
    min-height: 34px;
    padding: 10px 14px;
    &:after {
        line-height: 36px;
    }
}
.multiselect__tag-icon {
    line-height: 18px;
    &:after {
        color: $body-bg;
    }
}
.multiselect__input,
.multiselect__single {
    margin-bottom: 4px;
    max-width: 110px;
}
.multiselect__select {
    height: 32px;
    &:before {
        top: 59%;
    }
}
.multiselect--disabled {
    background: none;
}
.form-group-sm {
    .multiselect__select {
        height: 32px;
    }
}


// *****************************************************************************
// Timeline and list status colors.

[class^="book-item-status-"],
[class*=" book-item-status-"] {
  &:hover {
      .book-timeline-group-item-bkg {
          opacity: .98;
      }
  }
  .book-square {
      border-radius: 3px;
      display: block;
      height: 22px;
      width: 22px;
  }
}
.book-item-fadeout {
    opacity: .4;
}
.book-item-status-1 {
  &.book-item-bkg,
  .book-square {
      background: $color-list-item-passed;
      border: 1px solid lighten($color-grey-light, 2%);
      color: $color-list-item-text-passed;
  }
  .book-sidebar-header-title-text {
      color: $color-list-item-text-passed;
  }
  .book-timeline-group-item-bkg {
      fill: $color-list-item-passed;
  }
  .book-timeline-group-item-text {
      fill: $color-list-item-text-passed;
  }
}
.book-item-status-2 {
  &.book-item-bkg,
  .book-square {
      background: $color-list-item-signed;
      color: $color-list-item-text-signed;
  }
  .book-sidebar-header-title-text {
      color: $color-list-item-text-signed;
  }
  .book-timeline-group-item-bkg {
      fill: $color-list-item-signed;
  }
  .book-timeline-group-item-text {
      fill: $color-list-item-text-signed;
  }
}
.book-item-status-3 {
  &.book-item-bkg,
  .book-square {
      background: $color-list-item-unsigned-priority;
      color: $color-list-item-text-unsigned-priority;
  }
  .book-sidebar-header-title-text {
      color: $color-list-item-text-unsigned-priority;
  }
  .book-timeline-group-item-bkg {
      fill: $color-list-item-unsigned-priority;
  }
  .book-timeline-group-item-text {
      fill: $color-list-item-text-unsigned-priority;
  }
}
.book-item-status-4 {
  &.book-item-bkg,
  .book-square {
      background: $color-list-item-unsigned;
      color: $color-list-item-text-unsigned;
  }
  .book-sidebar-header-title-text {
      color: $color-list-item-text-unsigned;
  }
  .book-timeline-group-item-bkg {
      fill: $color-list-item-unsigned;
  }
  .book-timeline-group-item-text {
      fill: $color-list-item-text-unsigned;
  }
}
.book-item-status-5 {
  &.book-item-bkg,
  .book-square {
      background: $color-list-item-option;
      color: $color-list-item-text-option;
  }
  .book-sidebar-header-title-text {
      color: $color-list-item-text-option;
  }
  .book-timeline-group-item-bkg {
      fill: $color-list-item-option;
  }
  .book-timeline-group-item-text {
      fill: $color-list-item-text-option;
  }
}
.book-item-status-6 {
  &.book-item-bkg,
  .book-square {
      background: $color-production;
      color: $color-production-text;
  }
  .book-sidebar-header-title-text {
      color: $color-production-text;
  }
  .book-timeline-group-item-bkg {
      fill: $color-production;
  }
  .book-timeline-group-item-text {
      fill: $color-production-text;
  }
}
.book-item-status-7 {
    &.book-item-bkg,
    .book-square {
        background: $color-list-item-conflict-warning;
        color: $color-list-item-text-unsigned-priority;
    }
    .book-sidebar-header-title-text {
        color: $color-list-item-text-unsigned-priority;
    }
    .book-timeline-group-item-bkg {
        fill: $color-list-item-conflict-warning;
    }
    .book-timeline-group-item-text {
        fill: $color-list-item-text-unsigned-priority;
    }
  }
.book-item-status-8 {
    &.book-item-bkg,
    .book-square {
        background: $color-list-item-unsigned;
        color: $color-list-item-text-unsigned;
    }
    .book-sidebar-header-title-text {
        color: $color-list-item-text-unsigned;
    }
    .book-timeline-group-item-bkg {
        fill: $color-list-item-unsigned;
    }
    .book-timeline-group-item-text {
        fill: $color-list-item-selected-text-unsigned;
    }
  }
.book-timeline-group-item-bkg {
    opacity: .92;
}

// *****************************************************************************
// Panels.

.panel {
    .panel-heading {
        .label {
            vertical-align: calc(1px);
        }
    }
}


// *****************************************************************************
// Help text.

.book-help-message {
    display: block;
    margin: 0;
    padding: 5px 10px;
    text-align: center;
    + .book-help-message {
        padding-top: 0;
    }
}

// *****************************************************************************
// Sticky bars.

.book-top-sticky-wrapper {
    padding-top: 65px;
}
// @media (max-width: 525px){
//     .book-top-sticky-wrapper {
//         padding-top: 95px;
//     }
// }
.book-bottom-sticky-wrapper {
    padding-bottom: 100px;
}
.book-top-sticky {
//.book-bottom-sticky {
    background: rgba(#FFFFFF, .95);
    border: 0 solid $color-grey-border;
    left: 0;
    
    padding: 8px 15px !important;
    position: fixed;
    right: 0;
    z-index: 1003;
}

//.book-top-sticky,
.book-bottom-sticky {
    background: rgba(#FFFFFF, .95);
    border: 0 solid $color-grey-border;
    left: 0;
    margin: 0 !important;
    padding: 8px 15px !important;
    position: fixed;
    right: 0;
    z-index: 1003;
}

.book-top-sticky {
  border-bottom-width: 1px !important;
  top: 50px !important;
}
@media (max-width: 767px) {
    .book-top-sticky-wrapper {
        padding-top: 65px;
    }
    .book-top-sticky {
        top: 50px !important;
    }
}
@media (min-width: 768px) and (max-width: 872px) {
    .book-top-sticky-wrapper {
        padding-top: 210px;
    }
    .book-top-sticky {
        top: 195px !important;
    }
}
@media (min-width: 872px) and (max-width: 999px) {
    .book-top-sticky-wrapper {
        padding-top: 160px;
    }
    .book-top-sticky {
        top: 145px !important;
    }
}
@media (min-width: 999px) and (max-width: 1241px) {
    .book-top-sticky-wrapper {
        padding-top: 115px;
    }
    .book-top-sticky {
        top: 100px !important;
    }
}
@media (min-width: 1241px) {
    .book-top-sticky-wrapper {
        padding-top: 65px;
    }
    .book-top-sticky {
        top: 50px !important;
    }
}
.book-bottom-sticky {
  border-top-width: 1px !important;
  bottom: 0;
}

// *****************************************************************************
// New/updated markers.

@keyframes bookmarker {
    0% { opacity: .5; }
    30% { opacity: 1; }
    70% { opacity: 1; }
    100% { opacity: .5; }
}
.book-marker {
    cursor: default;
    display: inline-block;
    user-select: none;
    &:not([class*="-leave-active"]):not(g) {
        animation: bookmarker 2s ease 0s infinite;
    }
    rect {
      &[class*="label-"] {
          stroke: lighten($color-grey-light, 2%);
          stroke-width: 1px;
      }
    }
    rect.label-info {
        fill: $color-blue-info;
    }
    text.label-info {
        fill: $color-white;
    }
    rect.label-primary {
        fill: $color-blue-primary;
    }
    text.label-primary {
        fill: $color-white;
    }
    rect.label-danger {
        fill: $color-red;
    }
    text.label-danger {
        fill: $color-white;
    }
    rect.label-black {
        fill: $color-black;
    }
    text.label-black {
        fill: $color-white;
    }
}

@keyframes bookmarker-timeline {
    0% { opacity: .5; }
    30% { opacity: 1; }
    70% { opacity: 1; }
    100% { opacity: .5; }
}
.book-timeline-content-svg {
    .book-marker {
        cursor: pointer;
        &:not([class*="-leave-active"]) {
            animation: bookmarker 2s ease 0s infinite;
        }
        text {
            font-size: 10px;
        }
    }
}

// *****************************************************************************
// Box overlay (disabled).

.book-box-overlay {
    //background: rgba(#FFF, .8);
    bottom: 0;
    align-items: center;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 100;
    > span:not(.label) {
        padding: 10px;
    }
    > div:not(.label) {
       padding: 10px;
    }
}

// *****************************************************************************
// Approval box.

.book-approval-box {
    background: rgba($color-red, .95);
    bottom: 110px;
    color: $color-white;
    left: 0;
    padding: 8px 15px;
    position: fixed;
    right: 0;
    text-align: center;
    z-index: 1100;
    a {
        color: $color-grey-light;
        &:hover {
            color: $color-white;
        }
    }
}
