
.book-production-workgroup {
    min-height: 55px;
    position: relative;
    .book-production-workgroup-contact-buttons {
        position: absolute;
        right: 3px;
        top: -25px;
    }
    .book-table {
        border: none;
    }
    .table {
        margin-bottom: 0;
    }
    .book-table-no-results {
        min-height: 55px;
    }
}
