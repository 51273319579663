
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

#crewproductions {
    .panel > .book-crewproductions {
        margin-bottom: -1px;
    }
    .book-crewproductions {
        align-items: center;
        flex-flow: column;
        max-width: 100%;
        overflow-x: hidden;
        .book-crewproductions-day-navigation,
        .book-crewproductions-content {
            min-width: 800px;
            width: 100%;
        }
        .book-crewproductions-day-navigation-split {
            // min-width: 800px;
            //width: calc(100% - 130px);
            display: flex;
            height: 85px;
            //top: 0 !important;
        }
        .book-crewproductions-content-split {
            //width: calc(100% - 130px);
        }
        .book-crewproductions-day-navigation-split > .book-crewproductions-row-content {
            width: calc(100% - 130px);
        }
        .book-crewproductions-day-navigation-split {
            &.affix {
                background: $color-white;
                border: 1px solid $color-grey-border;
                border-bottom-width: 0;
                position: fixed;
                //left: 15px;
                width: calc(100% / 12 * 7 - 48px);
                z-index: 1000;
                //top: 0;
            }
            .book-crewproductions-label {
                display: inline-block;
            }
            .book-crewproductions-label,
            .book-crewproductions-column {
                border-bottom: 1px solid $color-grey-border;
            }
            .book-crewproductions-label > span {
                font-weight: bold;
                position: relative;
                text-align: left;
            }
            .book-crewproductions-column {
                padding: 2px 4px;
                // &.book-crewproductions-with-week {
                //     .book-crewproductions-day {
                //         padding-left: 25px;
                //     }
                // }
                &.book-crewproductions-today {
                    border-bottom-width: 1px;
                    border-top-width: 2px;
                }
            }
            .book-crewproductions-day {
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .book-crewproductions-week {
                background: $color-blue;
                // bottom: -4px;
                color: $color-white;
                // display: flex;
                // flex-direction: row;
                font-size: 12px;
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
                padding-top: 2px;
            }
            .book-crewproductions-week-empty {
                height: 20px;
                font-weight: bold;
                //padding: 5px 7px;
                position: relative;
                text-align: center;
                > * {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                margin-top: -2px;
                margin-left: -4px;
                margin-right: -4px;
            }
            //.book-crewproductions-week
            .book-crewproductions-weekday,
            .book-crewproductions-date {
                display: block;
            }
            .book-crewproductions-weekday {

            }
        }
        .affix + .book-crewproductions-content {
            margin-top: 56px;
        }
        .book-crewproductions-content {
            .book-crewproductions-column {
                padding: 2px 0;
            }
        }
        .book-crewproductions-day-navigation,
        .book-crewproductions-row {
            display: flex;
        }
        .book-crewproductions-row {
            min-height: 38px;
            &:last-child {
                .book-crewproductions-label,
                .book-crewproductions-column:not(.book-crewproductions-today) {
                    border-bottom: 1px solid darken($color-grey-light, 3%);
                }
                .book-crewproductions-column {
                    &.book-crewproductions-today {
                        border-bottom-width: 2px;
                    }
                }
            }
            &.book-crewproductions-odd {
                background: rgba($color-blue, .1);
            }
        }
        .book-crewproductions-label {
            display: flex;
            background: rgba($color-row-odd, .1);
            border-right: 1px solid darken($color-grey-light, 3%);
            max-width: 95px;
            min-width: 95px;
            overflow: hidden;
            padding: 2px 4px 2px;
            text-overflow: ellipsis;
            @include breakpoint(min-width $min-desktop) {
                max-width: 95px;
                min-width: 95px;
                padding-left: 5px;
                padding-right: 5px;
            }
            > span {
                user-select: none;
            }
        }
        .book-crewproductions-row-content {
            display: flex;
            flex: 1;
            min-width: 0;
        }
        .book-crewproductions-column {
            border-right: 1px solid darken($color-grey-light, 3%);
            flex: 1;
            min-width: 0;
            position: relative;
            z-index: 1;
            &:last-child {
                border-right: none;
            }
            &.book-crewproductions-today {
                border: 0 solid $color-blue;
                border-width: 0 2px;
                //margin-left: -1px;
                z-index: 2;
                // .book-crewproductions-week {
                //     bottom: -2px;
                // }
            }
        }
        .book-crewproductions-column-info-tools {
            display: inline-block;
            margin-right: 5px;
        }
        .book-crewproductions-label,
        .book-crewproductions-item {
            // font-size: 12px;
            // line-height: 1.3em;
        }
        .book-crewproductions-item {
            cursor: pointer;
            margin: 2px 4px;
            padding: 4px 5px 3px;
            user-select: none;
        }
        .book-crewproductions-item-top-row {
            display: flex;
            font-size: 10px;
        }
        .book-crewproductions-item-flag,
        .book-crewproductions-item-league,
        .book-crewproductions-item-title {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .book-crewproductions-item-time,
        .book-crewproductions-item-league,
        .book-crewproductions-item-flag {
            display: inline-block;
        }
        .book-crewproductions-item-title {
            display: block;
        }
        .book-crewproductions-item-time {
            flex: 1 0 auto;
        }
        .book-crewproductions-item-flag {
            flex-shrink: 1;
            &:before {
                display: inline-block;
                content: "|";
                margin: 0 3px;
            }
        }
        .book-crewproductions-item-league {
            flex: 30 1 auto;
            flex-shrink: 1;
            &:before {
                display: inline-block;
                content: "|";
                margin: 0 3px;
            }
        }
        .book-crewproductions-no-results {
            align-items: center;
            align-self: center;
            display: flex;
            flex: 1;
            padding: 10px;
            text-align: center;
            // > * {
            //     display: flex;
            // }
        }
        .book-crewproductions-item-plus {
            height: 20px;
            font-weight: bold;
            //padding: 5px 7px;
            position: relative;
            text-align: center;
            > * {
                overflow: hidden;
                text-overflow: ellipsis;
            }
            margin: 2px 4px;
            text-align: center;
        }
        .btn-plus {
            // position: absolute;
            // bottom: 1px;
            padding: 0px;
            width: 100%;
        }

        .column-week {
            max-width: 60px;
        }
        .column-day {
            max-width: 85px;
        }
        .column-date {
            max-width: 100px;
        }
        .column-time {
            max-width: 110px;
        }
        .column-runtype {
            max-width: 200px;
        }
        .column-channel {
            max-width: 150px;
        }

        .book-productions-no-results {
            align-items: center;
            align-self: center;
            display: flex;
            flex: 1;
            padding: 10px;
            text-align: center;
            // > * {
            //     display: flex;
            // }
        }
    }
}
