
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

    .book-overview-item-title.book-overview-item-title-recipient {
        width: 18%;
    }
    .book-overview-item-title.book-overview-item-title-subject {
        width: 15%;
    }
    .book-overview-item-title.book-overview-item-title-message {
        
    }
    .book-overview-item-title.book-overview-item-title-date {
        width: 10%;
    }
    .book-overview-item-title > span  {
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .book-table-actions {
        width: 25px !important;
    }
    .book-notification-modal {
    .book-notification-recipients-panel {
        .panel-heading {
            font-weight: 600;
        }
        .table-responsive {
            max-height: 100px;
        }
        .book-square {
            display: inline-block;
            height: 15px;
            vertical-align: middle;
            width: 15px;
        }
    }
    * + .book-notification-recipients-panel {
        margin-top: 14px;
    }
    .book-notification-group {
        margin-bottom: 10px !important;
        .book-notification-group-message {
            display: block;
            font-weight: 600;
            margin-bottom: 0;
        }
        .book-notification-group-items-label {
            display: inline-block;
            font-weight: 600;
            margin: 5px 5px 0 0;
            text-transform: capitalize;
            vertical-align: middle;
        }
        .book-notification-group-all label {
            // font-weight: 600 !important;
        }
        .radio-inline {
            // padding-top: 5px;
            label {
                font-weight: 400;
                // margin-bottom: 0;
            }
            input {
                margin-top: 3px;
            }
        }
    }
    .book-notification-type {
        margin-left: -15px !important;
        margin-right: -15px !important;
        padding: 0 15px;
    }
    .book-notification-type {
        // float: right;
        // margin-bottom: 3px !important;


        border-bottom: 1px solid $color-grey-border;


    }
    * + .book-notification-type {
        margin-top: 15px !important;
    }
    // .book-notification-type-content {
    //     margin-bottom: -15px !important;
    //     padding-bottom: 15px;
    // }

    .control-label {
        margin-bottom: 5px !important;
    }
    
}
.panel.panel-info {
        .panel-heading {
            .panel-header {
                display: inline-block;
            }
            .panel-date {
                float: right;
                display: inline-block;
            }
        }
    }
