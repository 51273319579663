
body.book-modal-opened {
    overflow: hidden !important;
}
.modal-header {
    .close {
        font-size: 25px;
    }
}
.modal-footer {
  .book-modal-footer-loading {
      display: inline-block;
      float: left;
      line-height: 30px;
      .book-loader,
      .book-modal-footer-loading-text {
          float: none;
      }
      .book-loader {
          display: inline-block;
          vertical-align: middle;
      }
      .book-modal-footer-loading-text {
          margin-left: 10px;
      }
  }

}
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .3s ease;
}

.modal-container {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    // max-width: 400px;
    margin: 0px auto;
    // padding: 20px 30px;
    transition: all .3s ease;
    // font-family: Helvetica, Arial, sans-serif;
    width: 90%;
}

.modal-header h3 {
    margin: 0;
    // margin-top: 0;
    // color: #42b983;
}
.modal-body {
    // margin: 20px 0;
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    .book-loading-text {
        margin-bottom: 10px;
        text-align: center;
    }
}
.modal-default-button {
    // float: right;
}
.modal-enter {
    opacity: 0;
}
.modal-leave-active {
    opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
    transform: scale(1.1);
}
