@import "../../../style/_variables.scss";

#production-edit {
  padding-bottom: 50px;

  .book-form-actions {
    overflow-x: auto;
  }

  .book-actions-flex  {
    justify-content: normal;
  }

    .panel {
        position: relative;
    }

  .book-box-housenumbers {
    display: inline-block;
    .form-group-sm {
      width: 210px;
      float: left;
    }
    .control-label-100 {
      text-align: left;
      padding-top: 15px;
      width: 50px;
    }
    .form-control-100 {
      padding-top: 10px;
      padding-left: 0px;
      margin-left: 0px;
      margin-right: 41px;
    }
  }

    .book-ingestion {
        position: absolute;
        right: 0;
        top: -24px;
        z-index: 5;
        margin-right: 58px;
    }
    .book-action-buttons {
        position: absolute;
        right: 0;
        top: -25px;
        z-index: 5;
        .btn-danger {
            margin-left: 5px;
        }
        .btn-info {
            margin-left: 5px;
        }
        .btn-special {
            margin-left: 5px;
        }
    }

    .book-fork-placeholder-missed-mandatory {
        border: 2px solid $color-red;
    }

    .form-control-static-text {
      padding-top: 4px;
      font-size: 12px;
    }

    .form-booked-by {
      display: flex;
      align-items: center;
    }

    .form-driver {
      display: flex;
      align-items: flex-start;
    }

    .form-driver-selection {
      flex: 1;
    }

}

@media print {

  #production-edit {

    .col-sm-6, .col-md-3, .col-md-6 {
      float: none;
      width: 100%;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background: none;
    }

  }

}
