
@import "../../../style/_variables.scss";
div.book-lock-wrapper {
    > * {
        display: inline-block;
    }
    .label {
        padding: 6px .6em;
        * + a {
            margin-left: 2px;
        }
    }
    a {
        color: $color-grey-light;
    }
}
* + div.book-lock-wrapper {
    border-left: 1px solid $color-grey-border;
    padding-left: 8px;
}
