

@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.book-calendar {
    position: relative;
    .fc-center {
        margin-left: auto;
        margin-right: auto;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .fc-event {
        border-color: $color-white;
        cursor: pointer;
        padding: 2px;
    }
    .fc-toolbar {
        &.fc-header-toolbar {
            //margin: 1em;
        }
        .fc-right {
            padding-top: 2px;
        }
    }
    .fc-week-number {
        background: $color-blue;
        color: $color-white;
    }
    .fa,
    .fas {
        // Fix font on icons.
        font: 900 normal normal 14px/1 "Font Awesome 5 Free" !important;
    }
    .event-with-info {
        width: 11px;
        height: 11px;
        background: white;
        border-radius: 50%;
        display: inline-block;
        margin-left: 5px;
        color: $color-list-item-conflict-warning;
        position: relative;
        vertical-align: middle;
        text-align: center;
        font-style: italic;
        font-weight: bold;
        font-family: "Font Awesome 5 Free";
        font-size: 10px;
    }
    .event-with-conflict {
        width: 11px;
        height: 11px;
        background: white;
        border-radius: 50%;
        border: 2px solid $color-red;
        display: inline-block;
        margin-left: 5px;
        color: $color-red;
        position: absolute;
        vertical-align: middle;
        text-align: center;
        font-style: italic;
        font-weight: bold;
        font-family: "Font Awesome 5 Free";
        font-size: 10px;
        top: 0;
        right: 0;
    }

    .event-with-conflict-line {
        width: 13px;
        height: 3px;
        background-color: $color-red;
        -webkit-transform:
            translateY(4px)
            translateX(-1px)
            rotate(45deg);
        position: absolute;
    }
}   
