
@import "../../style/_variables.scss";
.label-exceeded {
  background-color: $color-list-item-conflict-warning;
}
.book-width-60-percents {
  width: 60%;
}
.w-150 {
  max-width:175px;
  display: inline-block !important;
}
.sidebar-mcr-label {
  display: inline-block;
  padding: 3px;
  margin: 2px 4px 2px 0;
  border-radius: 5px;
  color: #fff;
  background: #337ab7;
  box-shadow: 2px 2px 1px 0 black;
}
