
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
#calendar-page {
    .cov-date-body,.datepicker-overlay {
        white-space: normal !important;
    }

    @media (min-width: 752px) {
        .no-gutter > [class*='col-'] {
            padding-right:0;
            padding-left:0;
        }
    }
    
    @media (max-width: 320px) {
        .no-gutter > [class*='col-'] {
            padding-right:15;
            padding-left:15;
        }   
    }

    @media (min-width: 725px){
        .row-padding{
            padding: 0 15px;
        }
    }


    .panel {
        > .book-calendar > #calendar {
            margin: -1px;
        }
    }
    @media (max-width: 464px) {
        .panel {
            top: 65px;
            position: relative;
        }
    }
    // @media (max-width: 397px) {
    //     .panel {
    //         top: 65px;
    //         position: relative;
    //     }
    // }
    // @media (min-width: 397px) and (max-width: 464px){
    //     .panel {
    //         top: 35px;
    //         position: relative;
    //     }
    // }
    @media (min-width: 465px) and (max-width: 765px) {
        .panel {
            top: 35px;
            position: relative;
        }
    }
    // // @media (min-width: 765px) and (max-width: 767px){
    // //     .panel {
    // //         top: 0;
    // //     }
    // // }
    // @media (min-width: 765px) and (max-width: 856px){
    //     .panel {
    //         top: 35px;
    //         position: relative;
    //     }
    // }
    // @media (min-width: 856px){
    //     .panel {
    //         top: 0;
    //     }
    // }
    @media (min-width: 765px){
        .panel {
            top: 0;
        }
    }
    .book-sidebar {
        .book-date-navigation {

        }

        .book-sidebar-header {
        }
        .book-sidebar-content {
            padding: 0;
            > div {
                overflow-y: auto;
                padding: 10px;
            }
        }
        .book-sidebar-event {
            border-bottom: 1px solid $color-grey-border;
            padding-bottom: 8px;
        }
        .book-sidebar-event-wrapper {
            display: block;
            .book-square {
                float: left;
            }
            .book-sidebar-event-content,
            .book-sidebar-event-title {
                line-height: 20px;
            }
            .book-sidebar-event-content {
                overflow: hidden;
                padding-left: 10px;
            }
            .book-sidebar-event-title {
                display: inline-block;
            }
        }
    }
}
