

.swal2-container {

}

.swal2-modal {
  button + button {
    margin-left: 5px;
  }
}

