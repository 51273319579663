
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

    .book-overview-item-title.book-overview-item-title-recipient {
       
    }
    .book-overview-item-title.book-overview-item-title-subject {
        
    }
    .book-overview-item-title.book-overview-item-title-message {
        width: 20%;
    }
    .book-overview-item-title.book-overview-item-title-date {
        width: 10%;
    }
    .book-overview-item-title > span  {
        /* autoprefixer: ignore next */
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .book-table-actions {
        width: 25px !important;
    }
    
    .panel.panel-info {
        .panel-heading {
            .panel-header {
                display: inline-block;
            }
            .panel-date {
                float: right;
                display: inline-block;
            }
        }
    }
