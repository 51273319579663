
.book-datetime {
    .book-datetime-datepicker-hidden {
        display: block !important;
        height: 0 !important;
        overflow: hidden !important;
        width: 0 !important;
    }
    .book-input-date,
    .book-input-time {
        float: left;
    }
    .book-input-date {
        width: 140px;
    }
    .book-input-time {
        margin-left: 5px;
        width: 62px;
    }
}
.form-group-sm {
    .book-datetime {
        .book-input-date {
            width: 110px;
        }
        .book-input-time {
            width: 42px;
        }
        .book-input-date,
        .book-input-time {
            float: left;
            .form-control  {
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }
}
