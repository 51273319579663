
.form-control {
  height: 33px;
  width: 100% !important;
}
.book-btn-create {
  box-shadow: 0px 2px 20px -5px $color-shadow;
  bottom: 25px;
  float: none !important;
  position: fixed !important;
  right: 30px;
  z-index: 1000 !important;
}
.form-horizontal {
    [class^="control-label-"],
    [class*=" control-label-"] {
        padding-right: 0;
        padding-top: 7px;
        @include breakpoint(min-width $min-tablet) {
            text-align: right;
        }
    }
    [class^="form-control-"]:not(.form-control-static),
    [class*=" form-control-"]:not(.form-control-static) {
      @include breakpoint(min-width $min-tablet) {
          float: right;
          padding-left: 15px;
      }
      .checkbox,
      .radio {
        padding-bottom: 7px;
        padding-top: 5px;
      }
    }
    .checkbox {
      padding-top: 10px;
      .checkbox-inline,
      .radio-inline {
        margin-left: 0 !important;
        margin-right: 10px;
        padding-top: 0;
      }
    }
    .form-group-sm {
        .form-control-static {
            min-height: 30px;
        }
    }
    input[type="radio"],
    input[type="checkbox"] {
        margin-top: 2px;
    }
    .form-control-required-symbol {
      color: rgba($color-red, .75);
      float: none;
      font-size: 8px;
      padding: 5px !important;
      text-align: center;
      vertical-align: calc(2px);
      width: 12px;
    }
    .form-group {
      margin-left: 0;
      margin-right: 0;
      // &.form-group-bordered {
      //     border: 1px solid $color-grey-border;
      //     border-radius: 3px;
      //     margin: -2px -6px;
      //     padding: 6px;
      // }
    }
    .control-label-120 {
        width: 100%;
        @include breakpoint(min-width $min-tablet) {
            width: 120px;
        }
    }
    .form-control-120 {
        width: 100%;
        @include breakpoint(min-width $min-tablet) {
            width: calc(100% - 120px);
        }
    }
    .control-label-100 {
        width: 100%;
        @include breakpoint(min-width $min-tablet) {
            width: 100px;
        }
    }
    .form-control-100 {
        width: 100%;
        @include breakpoint(min-width $min-tablet) {
            width: calc(100% - 100px);
        }
    }
    .control-label-20 {
        width: 100%;
        @include breakpoint(min-width $min-tablet) {
            width: 20px;
        }
    }
    .form-control-20 {
        width: 100%;
        @include breakpoint(min-width $min-tablet) {
            width: calc(100% - 20px);
        }
    }
    .control-label-80 {
        width: 100%;
        @include breakpoint(min-width $min-tablet) {
            width: 80px;
        }
    }
    .form-control-80 {
        width: 100%;
        @include breakpoint(min-width $min-tablet) {
            width: calc(100% - 80px);
        }
    }
    .book-creation-form {
        margin: 0 auto;
        max-width: 700px;
    }
}

.form-group {
  &:last-of-type {
    margin-bottom : 0;
  }
  &.inline-elements {
    > * {
      display: inline-block;
    }
  }
  .book-form-control-inline {
    display: inline-block;
    width: auto;
  }
  .book-checkbox-inline {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 3px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  // .book-form-control-datetime {
  //   .book-input-date,
  //   .book-input-time {
  //     float: left;
  //   }
  //   .book-input-date {
  //     width: 140px;
  //   }
  //   .book-input-time {
  //     margin-left: 5px;
  //     width: 62px;
  //   }
  // }
  .help-block {
    margin-bottom: 0;
  }
  .multiselect + .help-block {
    margin-top: -1px;
  }
}

.form-group {
  .form-control-feedback {
    &.fa-asterisk {
      color: $color-red;
    }
  }
}
.book-form-actions {
    * {
        display: inline-block;
    }
    .btn {
      margin-right: 4px;
      &:last-child {
          margin-right: 0;
      }
    }
}

.book-form-item-wrapper {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}
* {
  + .book-form-item-wrapper,
  + .book-file-upload {
    margin-top: 20px;
  }
}

.radio-inline {
  padding-top: 2px;
  * {
    cursor: pointer;
  }
}

.book-signoff-row {
  .control-label {
    padding-left: 0;
  }
}

.book-actions-flex {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}
.book-bottom-sticky {
    .book-form-actions-label {
        align-items: center;
        flex: 1;
        > * + * {
            border-left: 1px solid $color-grey-border;
            margin-left: 4px;
            padding-left: 10px;
        }
    }
    .book-form-actions-label-text {
        vertical-align: middle;
        .label {
            vertical-align: calc(1px);
        }
    }
    .book-form-actions-label-last-change,
    .book-form-actions-label-datetimes {
        align-items: center;
        display: inline-flex;
        font-size: 12px;
        line-height: 1.3;
        user-select: none;
        .fa {
            font-size: 18px;
            line-height: 1;
            margin-right: 8px;
        }
    }
    .book-form-actions-label-last-change {
        .book-form-actions-label-last-change-inner {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            span {
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .book-form-actions-label-datetimes {
        .book-form-actions-label-time {
            margin-left: 6px;
            font-weight: 600;
        }
    }
}

.book-compact-forms {
    .form-group {
        margin-bottom: 8px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .form-horizontal {
        [class^="form-control-"],
        [class*=" form-control-"] {
            @include breakpoint(min-width $min-tablet) {
                padding-left: 8px;
            }
        }
        [class^="control-label-"],
        [class*=" control-label-"] {
            @include breakpoint(min-width $min-tablet) {
                padding-top: 5px;
            }
        }
    }
}
.book-normal-forms {
    .form-group {
        margin-bottom: 15px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}


// *****************************************************************************
// Book radio tabs.

.book-radio-tabs {
    .radio-tab {
        background: $color-grey-light;
        border: 1px solid $color-grey-border;
        border-bottom: none;
        border-radius: 4px 4px 0 0;
        cursor: pointer;
        display: inline-block;
        vertical-align: bottom;
        &.active {
            background: $color-white;
            border-bottom-color: $color-white;
            font-size: 16px;
            margin-bottom: -1px;
            label {
                font-weight: 600;
            }
        }
    }
    input[type=radio] {
        display: none;
    }
    label {
        cursor: pointer;
        font-weight: 400;
        margin-bottom: 0 !important;
        padding: 4px 6px;
    }
}
// .book-radio-tabs-content {
//     background: $color-grey-light;
// }

fieldset {

    border: 1px solid $color-grey-border;
    border-radius: 0 0 4px 4px;
    padding: 10px;
    position: relative;

}
legend {
    background: $color-grey-border;
    border: none;
    border-radius: 4px 4px 0 0;
    margin-bottom: 0;
    padding: 4px 10px;
}
* + legend {
    margin-top: 20px;
}



// *****************************************************************************
// Tech Contracts.
// @TODO Add this to ContractEdit.vue ???


.book-contract-billing-type {
  align-items: center;
  display: flex;
  min-height: 35px;
  .radio-inline {
    padding-top: 2px;
  }
  .book-label {
    // flex: 1;
    margin-right: 20px;
    text-align: right;
  }
  .form-group {
    margin: 0;
  }
  .book-actions {
    margin-left: auto;
  }
}

* + [class*=book-contract-billing-] {
  padding-top: 15px;
}