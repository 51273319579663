
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
.book-content-with-sidebar {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    overflow: hidden;
    &.book-sidebar-opened {
        .book-sidebar {
            margin-right: 0;
            min-height: 300px;
        }
        .book-content {
            // justify-content: flex-start;
        }
    }
    .book-content {
        align-items: flex-start;
        display: flex;
        flex: 1;
    }
    @media (max-width: 320px){
        .book-content{
            padding: 0 5px;
        }
    }
    
    .book-sidebar {
        align-self: stretch;
        background: $color-white;
        //border-left: 1px solid $color-grey-border;
        margin-right: -300px;
        position: relative;
        transition: all .3s ease;
        width: 300px;
        //width: 100%;
        .book-item-status {
            border-radius: 3px;
            display: block;
            float: right;
            height: 20px;
            margin: 0;
            width: 20px;
        }
        
    }
    .full-width{
            min-width: 300px;
            width: 100%;
        }
    @media (max-width: 768px) {
        .book-sidebar{
            max-width: none;
            padding-top: 10px;
        }
    }
    @media (max-width: 552px){
        .book-sidebar{
            min-width: none;
        }
    }
    @media (max-width: 1500px) {
        .book-sidebar-header-title {
            font-size: 14px;
        }
    }
    .book-sidebar-sticky{
        position: fixed;
        width: 300px;
        z-index: 1002;
        overflow-y: scroll;
    }
    .book-sidebar-header {
        align-items: center;
        background: #e4e4e4;
        display: flex;
        height: 38px;
        padding-left: 10px;
        .book-sidebar-header-title {
            flex: 1;
            line-height: 1.4em;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .book-sidebar-header-title-text {
            flex: 1;
            vertical-align: calc(-2px);
        }
        .book-sidebar-header-title-inner {
            display: flex;
        }
        .book-action-button {
            height: 24px;
            line-height: 21px;
            margin: 0 2px 0;
            width: 24px;
            &:first-child {
                margin-left: 6px;
            }
            &:last-child {
                margin-right: 6px;
            }
        }
    }
    .book-sidebar-content {
        padding: 10px;
        background-color: #fff;
        &.book-sidebar-content-no-item {
            padding: 10px;
            text-align: center;
        }
    }
    *:not(.book-item-status) + .book-sidebar-row {
        // border-top: none;
        margin-top: 5px;
        padding-top: 5px;
    }
    .book-sidebar-row {
        // border-bottom: 1px solid lighten($color-black, 86%);
        display: flex;
        flex-flow: row wrap;
        line-height: 18px;
        // &:last-child {
        //     border-bottom: none;
        //     margin-bottom: 0;
        //     padding-bottom: 0;
        // }
        .book-sidebar-label {

        }
        button{
            margin-top:20px;
            min-width: 100px;
            margin-right: 20px;
        }
        .book-sidebar-value {
            display: flex;
            flex: 1;
        }
        // .book-sidebar-value-wrap {
        //     * {
        //         word-wrap: break-word;
        //     }
        // }
        .book-sidebar-value-stacked {
            flex-flow: row wrap;
            > .book-sidebar-value-stacked-item {
                width: 100%;
                + .book-sidebar-value-stacked-item {
                    margin-top: 3px;
                    padding-top: 3px;
                }
            }
        }
        .book-loader {
            align-self: center;
        }
    }
    // * + .book-sidebar-group {
    //     margin-top: 10px;
    // }
    .book-sidebar-group {
        // margin-left: -10px;
        // margin-right: -10px;
        margin-top: 10px;
        &:first-of-type {
            margin-top: 0;
        }
    }
    .book-sidebar-equipment-conflict {
        cursor: default;
        margin-right: 2px;
        user-select: none;
    }
    .book-sidebar-group-title,
    .book-sidebar-table-header {
        background: #e4e4e4;
        font-size: 14px;
        font-weight: 600;
        margin-left: -10px;
        margin-right: -10px;
        padding: 5px 10px;
    }
    .book-sidebar-table {
        .book-sidebar-table-header,
        .book-sidebar-table-row {
            display: flex;
            flex-flow: row wrap;
            > *:first-child {
                flex: 1;
            }
        }
        .book-sidebar-table-header {

        }
        .book-sidebar-table-row {
            line-height: 20px;
            margin: 3px 0;
            padding: 2px 0;
            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
            [class*=book-item-status-] {
                display: flex;
            }
            .book-square {
                display: inline-block;
                height: 15px;
                margin-right: 7px;
                vertical-align: calc(-2px);
                width: 15px;
            }
            .book-sidebar-table-row-text {

            }

        }
        * + .book-sidebar-table-row-extra {
            margin-top: 3px;
        }
        .book-sidebar-table-row-extra {
            color: lighten($color-text, 40%);
            flex-basis: 100% !important;
            font-style: italic;
        }
        .book-sidebar-table-row-nowrap {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .book-sidebar-table-period-column {
            width: 76px;
        }
    }
}

