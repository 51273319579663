
#usernotificationlist {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 70%;   

    .panel-heading-bold {
        font-weight: bold;
    }
    .panel-body-center {
        text-align: center;
    }

    .notification-time{
        position: relative;
    }

    .notification-time a {
        width: calc(100% - 120px);
        display: block;
    }

    .notification-time span{
        position: absolute;
        right: 0px;
        top: 0%;
    }

}
