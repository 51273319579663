
.book-production-plan {
    min-height: 40px;
    position: relative;
    .book-button-open-plan-item {
        position: absolute;
        right: 3px;
        top: -25px;
    }
    .book-production-plan-offset-wrapper {
        display: flex;
        flex-flow: row wrap;
        .form-control-static {
            align-items: center;
            display: flex;
            flex: 1;
            justify-content: flex-end;
            line-height: 1.2;
            padding: 6px 0 7px !important;
            text-align: right;
            > * {
                display: inline-block;
                vertical-align: middle;
            }
        }
        .book-production-plan-offset-date {
            font-size: 14px;
        }
        .book-production-plan-offset-time {
            font-size: 18px;
            font-weight: 600;
            margin-left: 4px;
        }
        .book-production-plan-offset {
            display: flex;
            .btn-group {
                .btn {
                    z-index: 1;
                    &:last-child {
                        border-radius: 0;
                    }
                    &:focus {
                        z-index: 2;
                    }
                }
            }
            .form-control {
                border-radius: 0 3px 3px 0;
                margin-left: -1px;
                width: 52px !important;
                z-index: 1;
                &:focus {
                    z-index: 2;
                }
            }
        }
    }
}
