
#feed-edit-print {
    .book-print-feed-audio-layout {
        .book-print-feed-audio-layout-isdn {
            width: 75px;
        }
    }
    .book-print-feed-encryption {
        .book-print-feed-encryption-country,
        .book-print-feed-encryption-value {
            display: block;
        }
        .book-print-feed-encryption-country {
            float: left;
        }
        .book-print-feed-encryption-value {
            overflow: hidden;
            padding: 1px 0 0 10px;
        }
        .book-print-feed-encryption-local {
            + .book-print-feed-encryption-local {
                margin-top: 5px;
            }
        }
    }
    .book-print-feed-local-notes {
        .book-print-feed-local-note-country,
        .book-print-feed-local-note-value {
            display: block;
        }
        .book-print-feed-local-note-country {
            float: left;
        }
        .book-print-feed-local-note-value {
            overflow: hidden;
            padding: 1px 0 0 10px;
        }
        .book-print-feed-local-note {
            + .book-print-feed-local-note {
                margin-top: 5px;
            }
        }
    }
}


#feed-edit {
    padding-bottom: 50px;
    .book-copyform-loading-text {
        margin-bottom: 10px;
        text-align: center;
    }

    .book-box-audio-layout {
        .control-label-100 {
            width: 60px;
        }
        .form-control-100 {
            width: calc(100% - 60px)
        }
    }
    .book-box-schedule {
        .control-label-100 {
            width: 75px;
        }
        .form-control-100 {
            width: calc(100% - 75px);
        }
    }
    .book-box-specifications {
        .control-label-100 {
            width: 90px;
        }
        .form-control-100 {
            width: calc(100% - 90px);
        }
    }

    .book-box-delivery-type {
        .book-delivery-type-sat {
            .control-label-100 {
                width: 70px;
            }
            .form-control-100 {
                width: calc(100% - 70px);
            }
            .row {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .book-panel-localnotes {
        .checkbox {
            margin-bottom: 10px;
        }
    }

}
