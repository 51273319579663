
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.book-type-group-label {
  // box-shadow: 0 3px 20px -8px rgba($color-shadow, 0.4);
  font-weight: bold;
  font-size: 1.1em;
  // margin-bottom: 5px;
  padding: 4px 0 4px 25px;

}
.book-type-group-label-row {
  background-color: #f6f6f6;
}
.book-type-group-label-row-expanded {
  background-color: #eceaea;
}
th.action {
  text-align: right;
  white-space: nowrap;
}
#commentators {
  td {

    white-space: nowrap;
  }
}

.book-label-capitilize {
  p:first-letter {
    text-transform:capitalize;
  }
}
