
.book-upload {
    position: relative;
    .progress {
        height: 33px;
    }
    .book-upload-actions {
        float: right;
        min-width: 100px;
        padding: 6px 6px 0 10px;
        width: 30%;
        .btn {
            display: block;
            margin-bottom: 10px;
            width: 100%;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
body {
    .dropzone {
        min-height: 0;
        padding: 4px;
    }
    .vue-dropzone {
        border: 2px dashed rgba(#FFFFFF, 0) !important;
        border-radius: 4px;
        background: none;

    }
    .vue-dropzone.dz-drag-hover {
        border-color: #D58512 !important;
    }
    .dropzone .dz-message {
        align-items: center;
        border-radius: 4px;
        display: flex;
        border: 2px dashed #DDDDDD;
        justify-content: center;
        margin: 0 0 10px;
        max-width: 70%;
        min-height: 76px;
        padding: 5px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .dropzone.dz-started .dz-message {
        display: flex;
    }
    .dropzone .dz-preview {
        align-items: center;
        background-color: #FCF8E3 !important;
        border-radius: 4px;
        border: 2px solid #D58512;
        display: flex;
        margin: 10px 0 0;
        min-height: 40px;
        overflow: hidden;
        padding: 10px;
    }
    .dropzone .dz-preview .dz-remove {
        display: none;
    }
    .dropzone .dz-preview .btn + .btn {
        margin-left: .2em;
    }
    .dropzone .dz-preview .dz-download-item,
    .dropzone .dz-preview .dz-remove-item,
    .dropzone .dz-preview .dz-delete-item {
        cursor: pointer;
        .fa {
          cursor: pointer;
        }
    }
    .dropzone .dz-preview .dz-remove-item {
        cursor: pointer;
        margin-left: 8px;
    }
    .dropzone .dz-preview .dz-download-item,
    .dropzone .dz-preview .dz-delete-item,
    .dropzone .dz-preview.dz-success .dz-remove-item {
        display: none;
    }
    .dropzone .dz-preview.dz-success .dz-download-item,
    .dropzone .dz-preview.dz-success .dz-delete-item {
        display: block;
    }
    .dropzone .dz-preview.dz-success {
        background-color: #F9F9F9;
        border-color: #EEEEEE;
    }
    .dropzone .dz-preview.dz-success .dz-progress {
        display: none;
    }
    .dropzone .dz-preview .dz-details {
        color: #333333;
    }
    .dropzone .dz-preview .dz-image {
        text-align: center;
    }
    .dropzone .dz-preview .dz-image .dz-file {
        color: #777777;
        display: none;
        font-size: 40px;
        line-height: 50px;
    }
    .dropzone .dz-preview.dz-file-preview .dz-image {
        background: #FFFFFF;
        border-radius: 0;
        // height: 40px !important;
        // width: 40px !important;
    }
    .dropzone .dz-preview.dz-file-preview .dz-image img {
        display: none;
    }
    .dropzone .dz-preview.dz-file-preview .dz-image .dz-file {
        display: block;
    }
    .dropzone .dz-preview .dz-image {
        background-color: #FFFFFF;
        height: 50px;
        margin: -7px 10px -7px -7px;
        width: 50px;
    }
    .dropzone .dz-preview:hover .dz-image img {
        filter: none;
        //transform: none;
    }
    .dropzone .dz-preview .dz-progress {
        background: #FCF8E3;
        border-radius: 2px;
        bottom: 2px;
        height: 5px;
        left: 55px;
        margin: 0;
        position: absolute;
        right: 2px;
        top: auto;
        width: auto;
    }
    .vue-dropzone .dz-preview .dz-progress .dz-upload {
        background: #D58512;
    }
    .dropzone .dz-preview:not(.dz-processing) .dz-progress {
        animation: none !important;
    }
    .dropzone .dz-preview .dz-success-mark,
    .dropzone .dz-preview .dz-error-mark {
        display: none;
        font-size: 28px;
        left: auto;
        margin: 0 0 0 16px !important;
        opacity: 1 !important;
        position: relative;
        text-align: center;
        top: auto;
        width: 40px !important;
    }
    .dropzone .dz-preview .dz-success-mark {
        color: #449D44;
    }
    .dropzone .dz-preview.dz-complete .dz-progress {
        display: none;
    }
    .dropzone .dz-preview.dz-success .dz-success-mark,
    .dropzone .dz-preview.dz-error .dz-error-mark {
        animation: none;
        display: block;
    }
    .vue-dropzone .dz-preview .dz-details {
        background: none;
        flex: 1;
        min-width: 0;
        opacity: 1;
        padding: 0;
        position: relative;
    }
    .dropzone .dz-preview .dz-details .dz-filename {
        font-size: 14px;
        overflow: hidden;
    }
    .dropzone .dz-preview .dz-details .dz-size {
        float: right;
        font-size: 14px;
        margin-bottom: 0;
    }
}
