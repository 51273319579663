
html {
    height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  position: relative;
}

#main-content:not(.book-print) {
  background: $color-grey-light;
}

img {
  height: auto;
  max-width: 100%;
}

.book-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
