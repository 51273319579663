
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../../style/_variables.scss";

.selectbox-style {
  position: absolute;
  z-index: 10;
  margin-left: 20px;
  margin-top: 3px;
  margin-right: -75px;
  border: 1px solid #CCC;
  border-radius: 3px 0 0 3px;
  float: none !important;
  -ms-flex: 1;
  flex: 1;
  padding: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label-conflict {
  height: 16px;
}

.label-exceeded {
  background-color: $color-list-item-conflict-warning;
}

.pull-right {
  margin-right: 5px;
}
.sidebar-box-set {
  box-shadow: black 1px 1px 1px 0px !important;
}
.conflict-box-color {
  color: white !important;
  background-color: #d9534f !important;
}
.exceed-box-color {
  color: white !important;
  background-color: #942522 !important;
}

.event-box-color {
  color: white !important;
  background-color: #226893 !important;
}

#equipment-usage-page .book-sidebar .book-equipment-selection .book-button-add-equipment {
  position: absolute;
  right: 8px;
  top: -25px;
}

