
.navbar-header {
    .navbar-brand {
        align-items: center;
        display: flex;
        font-size: 16px;
        line-height: 1.4em;
        padding: 7px 15px;
        &.navbar-brand-with-text {
            .navbar-brand-image {
                float: left;
            }
        }
        img {
            max-width: 45px;
        }
    }
    .navbar-brand-image {
        display: inline-block;
        + .navbar-brand-text {
            height: 35px;
            line-height: 1em;
            overflow: hidden;
            margin-left: 10px;
            margin-top: 3px;
            max-width: 70px;
        }
    }
    .navbar-brand-text {
        color: $color-white;
        display: inline-block;
        font-weight: 600;
        margin: 0;
    }
}

.navbar {
    margin-bottom: 0;
}
.navbar-inverse {
    background: $color-blue;
    .navbar-nav {
        > li > a {
            color: darken($color-white, 10%);
        }
        > li.active a,
        > li.active a:hover,
        > li.active a:focus,
        > .open > a,
        > .open > a:hover,
        > .open > a:focus {
            background-color: darken($color-blue, 5%);
        }
    }
}

.book-form-navigation {
  margin-bottom: -1px;
  position: relative;
  z-index: 2;
  > li {
      a {
        cursor: pointer;
        user-select: none;
        &:hover {
            // border-bottom-color: $color-blue-primary;
        }
      }
  }
  > li.active {
      > a,
      > a:hover {
          // background: $color-blue-primary;
          // border-color: $color-blue-primary;
          // color: $color-white;
      }
  }
}


.btn-group {
  * + .date-navigation {
    margin-left: -1px;
    input[type=text] {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  .date-navigation {

    float: left;

    &:not(:last-child) {
      margin-right: -1px;
      input[type=text] {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }

    input[type=text] {
      @extend .form-control;
      cursor: pointer;
      width: 100px !important;
    }

  }
}
