
#user-edit {
  .book-toggle {
    float: left;
  }
  .book-user-edit-approval-text {
    display: block;
    overflow: hidden;
    padding-left: 10px;
    // margin-top: 10px;
  }

  @media (max-width: 552px) {
    .user-edit-content{
      margin-top: 30px;
    }
  }
}
