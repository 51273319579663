@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";


#mcr {

    .book-mcr-items-wrapper {
        // margin-bottom: 20px;



    }

    .feedback-modal.title {
        color: #595959;
        font-size: 30px;
        text-align: center;
        font-weight: 600;
        text-transform: none;
        position: relative;
        margin: 0 0 .4em;
        padding: 0;
        display: block;
        word-wrap: break-word; 
    }

    .feedback-modal.content {
        font-size: 18px;
        text-align: center;
        font-weight: 300;
        position: relative;
        float: none;
        margin: 0;
        padding: 0;
        line-height: normal;
        color: #545454;
        word-wrap: break-word;
    }

    .feedback-modal.buttonswrapper {
        display: block;
        text-align: center;
    }


}

.theme-switch-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #585858;
  border-radius: 5px;
  padding: 5px;
  label {
    margin-bottom: 0px;
  }

  em {
    margin-left: 10px;
    font-size: 1rem;
  }
}
.theme-switch {
  display: inline-block;
  height: 19px;
  position: relative;
  width: 45px;
}

.theme-switch input {
  display:none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 12px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 12px;
}

input:checked + .slider {
  background-color: #66bb6a;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
