

@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";
// Navbar-top should not be infront of the filters (WN)
.navbar-fixed-top {
    z-index: 1003 !important;
}
.book-top-sticky {
    z-index: 1002 !important;
}
.book-crew-filter {
    display: flex;
    &.book-bottom-sticky.book-filter-advanced-filter-modal-open {
        z-index: 1050;
    }
    > * {
        display: inline-block;
    }
    .book-loader {
        flex: 1;
    }
    .btn + .btn {
        margin-left: 5px !important;
    }
    .book-filter-summary {
        align-items: center;
        border-right: 1px solid $color-grey-border;
        display: flex;
        margin-right: 10px;
        padding-right: 10px;
    }
    .book-filter-saved-wrapper,
    .book-filter-items-wrapper,
    .book-filter-items-inner-wrapper {
        align-items: center;
        display: flex;
        > * {
            margin: 2px 0 2px 10px !important;
            &:first-child {
                margin-left: 0 !important;
            }
        }
        button:disabled {
            opacity: .3;
        }
        .form-group {
            display: inline-block;
            &.book-selected {

                // .form-control {
                .dropdown-toggle {
                    border-color: $color-blue;
                }
            }
            .control-label {
                display: block;
                text-align: center;
            }
        }
    }
    .book-filter-saved-wrapper {
        flex: 1;
    }
    .book-filter-items-wrapper {
    }
    .book-filter-items {
        &.collapse:not(.in) {
            display: none;
        }
        @include breakpoint(max-width $max-tablet) {
            background: $color-white;
            border-top: 1px solid $color-grey-border !important;
            bottom: 52px;
            left: 0;
            margin-left: 0 !important;
            overflow: hidden;
            position: fixed;
            right: 0;
            z-index: 1000;
            &.collapsing,
            &.collapse.in {
            }
            .book-filter-items-inner-wrapper {
                padding: 8px 15px;
            }
        }
        @include breakpoint(min-width $min-netbook) {
            display: block !important;
        }
    }
    .book-filter-items-inner-wrapper {
        justify-content: flex-end;

    }
    .book-filter-items-toggle {
        @include breakpoint(min-width $min-netbook) {
            display: none;
        }
        &:focus {
            outline: 0;
        }
    }
    .book-select-dropdown {
        .dropdown-menu {
            left: auto;
            right: 0;
        }
    }
    .book-filter-advanced-filter {
        //border: 2px solid $color-grey-border;
        border-radius: 6px;
        padding-bottom: 10px;
    }
    .book-filter-advanced-filter-combine {
        .form-group {
            min-width: 190px;
            position: relative;
            + .form-group {
                padding-left: 30px;
                &:before {
                    //content: "or";
                    font-size: 1.6rem;
                    font-weight: 600;
                    left: 0;
                    line-height: 20px;
                    margin-top: -10px;
                    position: absolute;
                    text-align: center;
                    top: 50%;
                    width: 20px;
                }
            }
        }
    }
    .book-filter-advanced-filter-type-header {
        background: $color-grey-border;
        border-radius: 4px 4px 0 0;
        padding: 5px;
        text-align: center;
        text-transform: capitalize;
        &:first-child {
            margin-top: 0;
        }
    }
    .book-filter-advanced-filter-type {
        position: relative;
        &:nth-child(odd) {
            .book-filter-advanced-filter-item {
                border: 1px solid $color-bg-info;
            }
            .book-filter-advanced-filter-item-header {
                background: $color-bg-info;
            }
        }
        &:nth-child(even) {
            .book-filter-advanced-filter-item {
                border: 1px solid $color-bg-success;
            }
            .book-filter-advanced-filter-item-header {
                background: $color-bg-success;
            }
        }
        + .book-filter-advanced-filter-type {
            margin-top: 10px;
            &:before {
                display: block;
                //content: "or";
                font-size: 2rem;
                font-weight: 600;
                margin-bottom: 10px;
                text-align: center;
            }
        }
        .book-filter-advanced-filter-item {

            // border: 1px solid $color-grey-light;

            border-radius: 4px;
            display: flex;
            flex-flow: row wrap;
            margin: 0 10px;
            padding: 10px;
            + .book-filter-advanced-filter-item {
                margin-top: 15px;
                &:before {
                    display: block;
                    //content: "and";
                    flex-basis: 100%;
                    font-size: 1.6rem;
                    font-weight: 600;
                    margin-top: -45px;
                    text-align: center;
                }
            }
            .form-group {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .book-filter-advanced-filter-item-header {

            // background: $color-grey-light;

            border-radius: 4px 4px 0 0;
            flex: 1 0 100%;
            font-weight: 600;
            margin: -10px -10px 10px;
            padding: 4px;
            text-align: center;
        }
    }
    .book-filter-advanced-filter-item {
        .book-select-dropdown {
            .dropdown-menu {
                left: 0;
                right: auto;
            }
        }
    }
    .book-filter-text-wrapper {
        position: relative;
        input[type="text"] {
            padding-right: 30px;
        }
        .book-loader {
            position: absolute;
            right: 6px;
            top: 7px;
        }
    }

    .col-padding-filter-right {
        padding-left: 5px;
        padding-right: 0px;
    }

    .col-padding-filter-left {
        padding-left: 0px;
        padding-right: 14px;
    }
}
.bttn-fivepx {
  padding: 5px !important;
  height: 27px !important;
  font-size: 12px !important;
}
.blink-bg{
  animation: blinkingBackground 5s infinite;
}
@keyframes blinkingBackground{
  80%		{ background-color: #881d1d;}
  100%		{ background-color: #337ab7;}
}

