
@import "~breakpoint-sass/stylesheets/breakpoint";
@import "../../style/_variables.scss";

.book-ingestion {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  &.book-ingestion-processing {
    .book-ingestion-item {
      opacity: 0 !important;
    }
  }
  .book-ingestion-item {
    background: none;
    border: 2px solid rgba($color-text, .5);
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    padding: 0;
    position: relative;
    user-select: none;
    vertical-align: middle;
    width: 20px;
    // z-index: 1;
    &:focus {
      outline: none;
    }
    &:before {
      display: block;
      content: " ";
      left: 50%;
      opacity: .5;
      top: 50%;
    }
    // &:after {
    //     border-top: 2px solid $color-red;
    //     display: block;
    //     content: " ";
    //     left: -8px;
    //     position: absolute;
    //     top: 7px;
    //     transform: rotate(45deg);
    //     width: 32px;
    // }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly),
    &.book-ingestion-item-active {
      border-color: rgba($color-text, 1);
      &:before {
        opacity: 1;
      }
      // &:after {
      //     display: none;
      // }
      svg {
        opacity: 1;
      }
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      box-shadow: 0px 1px 10px -1px $color-shadow;
    }
    &.book-ingestion-item-disabled {
      border-color: $color-grey-border;
      cursor: not-allowed;
      opacity: .6 !important;
      &:before {
        //opacity: 1;
      }
      &:after {
        //display: none;
      }
      svg {
        opacity: 1 !important;
      }
    }
    &.book-ingestion-item-readonly {
      cursor: default !important;
    }
    + .book-ingestion-item {
      margin-left: 4px;
    }
    svg {
      opacity: .5;
    }
  }
  .book-ingestion-item-notrecord {
    //border-color: rgba($color-text, 1);
    &:before {
      svg {
        opacity: 1;
        display: none;
      }
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      border-color: rgba($color-text, 1);
      &:before {
        background: $color-red;
        border-radius: 100%;
        position: absolute;
        height: 10px;
        margin: -5px 0 0 -5px;
        opacity: 1;
        width: 10px;
      }
      &:after {
        border-top: 2px solid $color-red;
        display: none;
        content: " ";
        left: -8px;
        position: absolute;
        top: 7px;
        transform: rotate(45deg);
        width: 32px;
      }
    }
    &.book-ingestion-item-active {
      border-color: rgba($color-text, 1);
      &:before {
        background: $color-red;
        border-radius: 100%;
        position: absolute;
        height: 10px;
        margin: -5px 0 0 -5px;
        opacity: 1;
        width: 10px;
      }
      &:after {
        border-top: 2px solid $color-red;
        display: none;
        content: " ";
        left: -8px;
        position: absolute;
        top: 7px;
        transform: rotate(45deg);
        width: 32px;
      }
    }
    &.book-ingestion-item-requested {
      border-color: rgba($color-text, 1);
      &:before {
        background: $color-yellow;
        border-radius: 100%;
        position: absolute;
        height: 10px;
        margin: -5px 0 0 -5px;
        opacity: 1;
        width: 10px;
      }
      &:after {
        border-top: 2px solid $color-yellow;
        display: none;
        content: " ";
        left: -8px;
        position: absolute;
        top: 7px;
        transform: rotate(45deg);
        width: 32px;
      }
    }
    &.book-ingestion-item-disabled {
      //border: 2px solid rgba($color-text, 1);
      //opacity: 1 !important;
      &:before {
        // background: $color-grey-border;
      }
    }
  }

  .book-ingestion-item-notick {
    border-color: rgba($color-text, 1);
    &:before {
      background: $color-red;
      border-radius: 100%;
      position: absolute;
      height: 10px;
      margin: -5px 0 0 -5px;
      opacity: 0.5;
      width: 10px;
    }
    &:after {
      border-top: 2px solid $color-red;
      content: " ";
      left: -8px;
      position: absolute;
      top: 7px;
      display: block;
      transform: rotate(45deg);
      width: 32px;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:before {
        background: $color-red;
        border-radius: 100%;
        position: absolute;
        height: 10px;
        margin: -5px 0 0 -5px;
        opacity: 0.5;
        width: 10px;
      }
      &:after {
        border-top: 2px solid $color-red;
        content: " ";
        left: -8px;
        position: absolute;
        top: 7px;
        display: block;
        transform: rotate(45deg);
        width: 32px;
      }
    }
    &.book-ingestion-item-active {
      border-color: rgba($color-text, .5);
      &:before {
        opacity: .5;
      }
      &:after {
        display: block;
      }
    }
    &.book-ingestion-item-disabled {
      border: 2px solid #8b8c8b;
      opacity: 1 !important;
      &:after {
        opacity: .5;
      }
    }
  }


  .book-ingestion-item-repeat,
  .book-ingestion-item-start,
  .book-ingestion-item-finish,
  .book-ingestion-item-air,
  .book-ingestion-item-routed,
  .book-ingestion-item-share {
    &.book-ingestion-item-disabled:not(.book-ingestion-item-active),
    &.book-ingestion-item-readonly:not(.book-ingestion-item-active),
    &:not(.book-ingestion-item-active):not(:hover) {
      &:before,
      svg {
        display: none;
      }
    }
  }

  .book-ingestion-item-repeat {
    &:before {
      border-style: solid;
      border-width: 6px 0 6px 10px;
      border-color: transparent transparent transparent $color-red;
      height: 0;
      position: absolute;
      margin: -6px 0 0 -4px;
      width: 0;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:after {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
        cursor: not-allowed;
        opacity: .6 !important;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }

  }
  .book-ingestion-item-share {
    color: $color-red;
    &:hover:not(.book-ingestion-item-active) {
      &:before {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }
    svg {
      display: inline-block;
    }
  }
  // tx Status coloring
  .book-ingestion-item-start {
    &:before {
      background-color: $color-red;
      width: 5px;
      height: 12px;
      border-radius: 100px;
      margin-left: 2px;
      padding-left: 12px;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:after {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
        cursor: not-allowed;
        opacity: .6 !important;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }

  }
  .book-ingestion-item-finish {
    &:before {
      background-color: #bfbebe;
      width: 5px;
      height: 12px;
      border-radius: 100px;
      margin-left: 2px;
      padding-left: 12px;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:after {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
        cursor: not-allowed;
        opacity: .6 !important;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }

  }
  .book-ingestion-item-air {
    &:before {
      background-color: $color-green;
      width: 5px;
      height: 12px;
      border-radius: 100px;
      margin-left: 2px;
      padding-left: 12px;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:after {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
        cursor: not-allowed;
        opacity: .6 !important;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }

  }
  // tx Off
  // viaplay routed
  .book-ingestion-item-routed {
    &:before {
      background-color: $color-yellow;
      width: 5px;
      height: 12px;
      border-radius: 100px;
      margin-left: 2px;
      padding-left: 12px;
    }
    &:hover:not(.book-ingestion-item-disabled):not(.book-ingestion-item-readonly) {
      &:after {
        display: none;
      }
    }
    &.book-ingestion-item-active {
      &.book-ingestion-item-disabled {
        border: 2px solid #8b8c8b;
        cursor: not-allowed;
        opacity: .6 !important;
      }
      &:before {
        //border-color: transparent transparent transparent $color-grey-border;
        display: block;
        opacity: 1;
      }
    }

  }
  //off

  .book-ingestion-overlay {
    bottom: 0;
    cursor: not-allowed;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    .book-loader {
      position: relative;
      z-index: 3;
    }
  }
}

